<template>
    <div v-if="displayNavigation" class="mb-3">
        <div v-if="method">
            <b-btn @click="changeMethod" variant="link" class="p-0">
                <font-awesome-icon size="lg" icon="fa-light fa-chevron-left" />
                {{ $t("Payment Methods") }}
            </b-btn>
        </div>
        <div v-else>
            <b-btn @click="backToWallet" variant="link" class="p-0">
                <font-awesome-icon size="lg" icon="fa-light fa-chevron-left" />
                {{ $t("Back to Wallet") }}
            </b-btn>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        displayNavigation() {
            if (this.errorCode && !this.method) {
                return false;
            }

            if (this.method === "authorize" && this.price) {
                return false;
            }

            return true;
        },
        ...mapState("wallet", ["method", "errorCode", "price"]),
    },
    methods: {
        changeMethod() {
            this.$store.commit("wallet/setMethod", null);
        },
        backToWallet() {
            this.$store.commit("wallet/reset");
        },
    },
};
</script>