<template>
    <generic-page>
        <div class="mb-2">
            <b-btn variant="link" size="lg" class="pl-0" to="/account/requests">
                <font-awesome-icon size="lg" icon="fa-light fa-chevron-left" />
                Back to Requests
            </b-btn>
        </div>

        <div v-if="creatorRequest">
            <div v-if="creatorRequest.dispute">
                <creator-request-dispute-index />
            </div>
            <div class="d-flex align-items-center justify-content-between">
                <h5>Request</h5>
            </div>

            <user-header
                class="border-bottom"
                :disableInterest="true"
                :standardMap="creatorRequest.creator"
            >
            </user-header>

            <b-table-simple bordered class="mb-0">
                <tbody>
                    <tr>
                        <th class="bg-light w-50">Amount</th>
                        <td class="bg-white">
                            <b-badge pill variant="light" class="mr-1">
                                <font-awesome-icon
                                    icon="fa-duotone fa-coin"
                                    class="text-warning"
                                    size="lg"
                                />
                                {{ creatorRequest.price }}
                            </b-badge>
                        </td>
                    </tr>
                    <tr>
                        <th class="bg-light">Status</th>
                        <td class="bg-white">
                            <creator-request-status
                                :creatorRequest="creatorRequest"
                            />
                        </td>
                    </tr>
                    <tr>
                        <th class="bg-light">Seen by Creator</th>
                        <td class="bg-white">
                            <font-awesome-icon
                                v-if="creatorRequest.seen"
                                class="text-success"
                                icon="fa-light fa-check"
                            />
                        </td>
                    </tr>
                    <tr>
                        <th class="bg-light">Credits in Escrow</th>
                        <td class="bg-white">
                            <font-awesome-icon
                                v-if="creatorRequest.escrow"
                                class="text-success"
                                icon="fa-light fa-check"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td class="bg-white" colspan="2">
                            <div class="font-weight-bold">Instructions</div>
                            <div>
                                {{ creatorRequest.request }}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </b-table-simple>

            <div class="d-flex justify-content-between align-items-center mt-3">
                <h5>Content</h5>
                <div v-if="creatorRequest.fulfilledAt">
                    <creator-request-dispute :creatorRequest="creatorRequest" />
                </div>
            </div>
            <b-alert
                v-if="creatorRequest.code"
                variant="warning"
                show
                class="mb-0"
            >
                <div class="d-flex justify-content-between align-items-center">
                    <h5 class="mb-0">
                        {{ $t("Authenticity Code") }}
                    </h5>
                    <div>
                        <font-awesome-icon icon="fa-light fa-arrow-right" />
                    </div>
                    <h1 class="mb-0">
                        {{ creatorRequest.code }}
                    </h1>
                </div>
                <div>
                    <font-awesome-icon icon="fa-duotone fa-info-circle" />
                    {{
                        $t(
                            "Ensure the original content visually includes the code. Do not accept the content if it does not contain the code, or if the code was digitally added"
                        )
                    }}
                </div>
            </b-alert>
            <div class="bg-white p-2">
                <div v-if="creatorRequest.medias?.length">
                    <b-row no-gutters>
                        <b-col
                            cols="4"
                            :key="userMedia.id"
                            v-for="userMedia in creatorRequest.medias"
                            class="p-1"
                        >
                            <media-item :userMedia="userMedia" />
                            <b-btn
                                target="_blank"
                                variant="primary"
                                :href="userMedia.url"
                                block
                            >
                                {{ $t("View") }}
                            </b-btn>
                        </b-col>
                    </b-row>

                    <div
                        class="mt-3 border-top text-center"
                        v-if="creatorRequest.canAccept"
                    >
                        <h5 class="mt-3">Satisfied with the content?</h5>
                        <div class="my-2 branded">
                            <font-awesome-icon icon="fa-light fa-arrow-down" />
                        </div>
                        <b-btn
                            :disabled="saving"
                            @click="acceptContent"
                            variant="primary"
                            pill
                            block
                            size="lg"
                            class="py-3"
                        >
                            Accept Content
                        </b-btn>
                        <div class="small text-secondary text-center my-2">
                            Requests will auto-accept in
                            {{ creatorRequestConfig?.releaseEscrowHours }} hours
                            if not disputed
                        </div>
                    </div>
                </div>
                <div v-else>
                    <b-alert
                        v-if="creatorRequest.refunded"
                        variant="primary"
                        show
                        class="mb-0"
                    >
                        <h5>Refunded</h5>
                        <div
                            v-if="creatorRequestConfig"
                            class="small mt-2 text-secondary"
                        >
                            <font-awesome-icon
                                icon="fa-duotone fa-info-circle"
                            />
                            Your request was refunded, and credits returned back
                            to your wallet
                        </div>
                    </b-alert>
                    <b-alert v-else variant="primary" show class="mb-0">
                        <h5>Waiting for content...</h5>
                        <div
                            v-if="creatorRequestConfig"
                            class="small mt-2 text-secondary"
                        >
                            <font-awesome-icon
                                icon="fa-duotone fa-info-circle"
                            />
                            Creators have
                            {{ creatorRequestConfig.fulfillmentDueHours }} hours
                            to submit their content, if they do not respond, you
                            will automatically be refunded
                        </div>
                    </b-alert>
                </div>
            </div>
        </div>
    </generic-page>
</template>

<script>
import CreatorRequestDisputeIndex from "@/components/account/creatorRequests/CreatorRequestDisputeIndex";
import CreatorRequestDispute from "@/components/account/creatorRequests/CreatorRequestDispute";
import CreatorRequestStatus from "@/components/creatorRequest/CreatorRequestStatus";
import UserHeader from "@/components/userHeader/UserHeader";
import MediaItem from "@/components/media/MediaItem";
import { mapState } from "vuex";

export default {
    data() {
        return {
            saving: false,
        };
    },
    created() {
        if (this.$route.params?.id) {
            this.$store.dispatch("request/get", this.$route.params?.id);
        }
    },
    watch: {
        $route(to) {
            if (
                to?.params?.id !==
                this.$store.state.creatorRequest.creatorRequest?.id
            ) {
                this.$store.dispatch("request/get", to.params.id);
            }
        },
    },
    methods: {
        acceptContent() {
            this.saving = true;

            this.api
                .post(`/creatorRequest/${this.creatorRequest.id}/accept`)
                .then(() => {
                    this.$store.dispatch("request/refresh");
                })
                .catch(() => {})
                .then(() => {
                    this.saving = false;
                });
        },
    },
    computed: {
        ...mapState("request", [
            "creatorRequest",
            "disputeRate",
            "creatorRequestConfig",
        ]),
    },
    components: {
        UserHeader,
        MediaItem,
        CreatorRequestDispute,
        CreatorRequestStatus,
        CreatorRequestDisputeIndex,
    },
};
</script>