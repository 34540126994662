<template>
    <div>
        <div class="mt-3 text-left">
            <div v-if="currentRule">
                <div>
                    <h3 class="text-danger">
                        <font-awesome-icon
                            class="text-danger"
                            icon="fa-solid fa-ban"
                        />
                        {{ currentRule.title }}
                    </h3>

                    <div class="mt-3 lead">
                        {{ currentRule.description }}
                    </div>
                </div>

                <b-btn
                    class="mt-3"
                    :disabled="!canContinue"
                    @click="finishRule(currentRule)"
                    variant="secondary"
                    pill
                    block
                >
                    {{ $t("Next Rule") }}
                </b-btn>
            </div>
            <div v-else-if="loaded">
                <div class="lead">
                    {{
                        $t(
                            "I have read and understand all of the rules, and agree to follow them. If any of the rules are violated, I understand my account may be restricted or banned without warning, incurring fines or requiring an entry fee to return to the platform"
                        )
                    }}
                </div>
                <b-btn
                    class="mt-3"
                    @click="agree"
                    variant="primary"
                    pill
                    size="lg"
                    block
                >
                    {{ $t("Agree") }}
                </b-btn>
                <b-btn
                    class="mt-3"
                    @click="startOver"
                    variant="secondary"
                    pill
                    size="lg"
                    block
                >
                    {{ $t("I want to read the rules again") }}
                </b-btn>
                <div class="mt-3 small">
                    <font-awesome-icon icon="fa-duotone fa-info-circle" />
                    {{
                        $t(
                            "Rules can also be viewed from your dashboard at anytime after you agree"
                        )
                    }}
                </div>
            </div>
            <div v-else>
                <generic-loading />
            </div>
        </div>
    </div>
</template>


<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            rules: null,
            finishedRule: [],
            canContinue: false,
            loaded: false,
        };
    },
    created() {
        this.refresh();
    },
    computed: {
        currentRule() {
            return this.rules?.find(
                (rule) => !this.finishedRule.includes(rule.id)
            );
        },
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        holdContinue() {
            setTimeout(() => {
                this.canContinue = true;
            }, 1000);
        },
        finishRule(rule) {
            this.canContinue = false;
            this.holdContinue();
            this.finishedRule.push(rule.id);
        },
        refresh() {
            this.api.post("/rules/index").then((response) => {
                this.rules = response?.data?.rules;
                this.holdContinue();
                this.loaded = true;
            });
        },
        startOver() {
            this.finishedRule = [];
        },
        agree() {
            this.api.post("/rules/agree").then(() => {
                this.$store.dispatch("onboarding/status");
            });
        },
    },
};
</script>
