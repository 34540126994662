<template>
    <div>
        <b-button v-b-toggle.howCreditsWork variant="light" block pill>
            <font-awesome-icon icon="fa-duotone fa-info-circle" />
            {{ $t("Credits & Billing Information") }}
        </b-button>
        <b-collapse id="howCreditsWork">
            <div
                class="alert alert-primary border-0 rounded-0 text-left mb-0 mt-3"
            >
                <h5 class="">
                    {{ $t("Simple & Transparent Billing") }}
                </h5>
                <div class="mt-1">
                    <font-awesome-icon class="mr-1" icon="fa-light fa-check" />
                    {{ $t("No subscriptions, pay as you go") }}
                </div>
                <div class="mt-1">
                    <font-awesome-icon class="mr-1" icon="fa-light fa-check" />
                    {{ $t("Credits never expire") }}
                </div>
                <div class="mt-1">
                    <font-awesome-icon class="mr-1" icon="fa-light fa-check" />
                    {{ $t("No hidden fees or recurring charges") }}
                </div>

                <h5 class="mt-4">
                    {{ $t("Secure & Discreet") }}
                </h5>
                <div class="mt-1">
                    <font-awesome-icon class="mr-1" icon="fa-light fa-check" />
                    {{
                        $t(
                            "Your sensitive card details are never stored on our systems"
                        )
                    }}
                </div>
                <div class="mt-1">
                    <font-awesome-icon class="mr-1" icon="fa-light fa-check" />
                    {{
                        $t(
                            "Transactions on your statement will be appear as: FINDMATE, LLC"
                        )
                    }}
                </div>

                <h5 class="mt-4">
                    {{ $t("Straightforward Pricing") }}
                </h5>
                <b-list-group>
                    <b-list-group-item
                        class="d-flex align-items-center justify-content-between"
                    >
                        {{ $t("Chatting") }}
                        <b-badge pill variant="light">
                            <font-awesome-icon
                                icon="fa-duotone fa-coin"
                                class="text-warning"
                                size="lg"
                            />
                            {{ prices.message }}
                            {{ $t("per message") }}
                        </b-badge>
                    </b-list-group-item>
                    <b-list-group-item
                        class="d-flex align-items-center justify-content-between"
                    >
                        {{ $t("Video Calling") }}
                        <b-badge pill variant="light">
                            <font-awesome-icon
                                icon="fa-duotone fa-coin"
                                class="text-warning"
                                size="lg"
                            />
                            {{ prices.call }}
                            {{ $t("per minute") }}
                        </b-badge>
                    </b-list-group-item>
                </b-list-group>
            </div>
        </b-collapse>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("options", ["prices"]),
    },
};
</script>