/* global FirebasePlugin */

import api from "@/service/api";

const state = () => ({
    initialized: false,
})

const mutations = {
    initialized(state) {
        state.initialized = true
    },
}


const actions = {
    initialize({ state, commit, dispatch }) {
        if (state.initialized) {
            return;
        }

        commit('initialized')

        FirebasePlugin.grantPermission((hasPermission) => {
            const eventString = 'push_permission_' + (hasPermission ? "granted" : "denied");
            console.log(eventString)
        });

        FirebasePlugin.setAutoInitEnabled(true, () => { })

        FirebasePlugin.setBadgeNumber(0);

        FirebasePlugin.onMessageReceived((message) => {

            if (!message.tap) {
                return
            }

            if (!('type' in message)) {
                return;
            }

            if (message.type == 'interest') {
                dispatch("system/appendPostLaunchAction", {
                    action: "profile/viewProfile",
                    payload: { userId: message.fromUserId }
                }, { root: true });
            }

            if (message.type == 'message' || message.type == 'photo' || message.type == 'video') {
                dispatch("system/appendPostLaunchAction", {
                    action: "profile/viewProfile",
                    payload: {
                        userId: message.fromUserId,
                        view: 'conversation'
                    }
                }, { root: true });
            }
        }, (error) => {
            console.error(error);
        })

        FirebasePlugin.getToken(token => {
            api.post('/push/saveToken', { token })
        }, (error) => {
            console.error(error)
        })

        FirebasePlugin.onTokenRefresh(token => {
            api.post('/push/saveToken', { token })
        }, (error) => {
            console.error(error)
        });
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}



