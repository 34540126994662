<template>
    <generic-page>
        <h5 class="mt-3">
            {{ $t("Delete Account") }}
        </h5>
        <div v-if="selectedReason">
            <div v-if="resolution">
                <delete-resolution />
            </div>
            <div v-else-if="scheduled">
                <delete-scheduled />
            </div>
            <div v-else-if="undeleteable">
                <delete-manual />
            </div>
            <div v-else>
                <delete-confirmation />
            </div>
        </div>
        <div v-else>
            <div v-if="offerAffiliate">
                <delete-affiliate />
            </div>
            <div v-else>
                <delete-reason-index />
            </div>
        </div>
    </generic-page>
</template>

<script>
import { mapState } from "vuex";

import DeleteConfirmation from "@/components/delete/DeleteConfirmation";
import DeleteManual from "@/components/delete/DeleteManual";
import DeleteReasonIndex from "@/components/delete/DeleteReasonIndex";
import DeleteResolution from "@/components/delete/DeleteResolution";
import DeleteScheduled from "@/components/delete/DeleteScheduled";
import DeleteAffiliate from "@/components/delete/DeleteAffiliate";

export default {
    mounted() {
        this.$store.commit("delete/reset");
    },
    computed: {
        // causes more problems than it solves
        // but might reuse this UI for something else
        offerAffiliate() {
            return false;
            // return this.onboardingUser.creator && !this.declinedAffiliate;
        },
        ...mapState("delete", [
            "selectedReason",
            "scheduled",
            "declinedAffiliate",
            "undeleteable",
            "resolution",
        ]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {},
    components: {
        DeleteConfirmation,
        DeleteManual,
        DeleteReasonIndex,
        DeleteResolution,
        DeleteScheduled,
        DeleteAffiliate,
    },
};
</script>