import api from "@/service/api";

const dateRangeDays = 30

const state = () => ({
    dashboard: null,
    creatorUser: null,
    creatorUserMeta: null,
    dateRangeDays,
})

const mutations = {
    setDateRangeDays(state, value) {
        state.dateRangeDays = value;
    },
    setDashboard(state, source) {
        state.dashboard = source;
    },
    setCreatorUser(state, value) {
        state.creatorUser = value;
    },
    setCreatorUserMeta(state, value) {
        state.creatorUserMeta = value;
    },
}

const actions = {
    refresh({ commit, state }) {
        api.post('/creator/dashboard', {
            dateRangeDays: state.dateRangeDays
        }).then((response) => {
            commit('setDashboard', response?.data?.dashboard)
            commit('setCreatorUser', response?.data?.creatorUser)
            commit('setCreatorUserMeta', response?.data?.creatorUserMeta)
        });
    },
}


const getters = {
    hasUserFlag: (state) => (flag) => {
        return state.userFlag?.includes(flag)
    },
}


export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
