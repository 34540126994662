<template>
    <div v-if="onboardingUser">
        <div v-if="!onboardingUser.ghostMode">
            <account-section-title>
                <div class="d-flex align-items-center justify-content-between">
                    <div>Profile</div>

                    <b-btn
                        @click="previewProfile"
                        variant="light"
                        pill
                        size="sm"
                        class="btn-brand"
                    >
                        <font-awesome-icon icon="fa-duotone fa-eye" />
                        {{ $t("Preview") }}
                    </b-btn>
                </div>
            </account-section-title>

            <b-list-group flush>
                <b-list-group-item
                    @click="
                        $store.dispatch('setting/editOnboardingStep', 'photo')
                    "
                    button
                    class="setting-item"
                >
                    <div class="label">
                        {{ $t("Photos") }}
                    </div>
                    <div class="value d-flex">
                        <div
                            class="small-avatar mr-1"
                            :key="photo.id"
                            v-for="photo in firstSixPhotos"
                        >
                            <img class="img-fluid rounded" :src="photo.small" />
                        </div>
                    </div>
                </b-list-group-item>
                <b-list-group-item
                    @click="
                        $store.dispatch('setting/editOnboardingStep', 'name')
                    "
                    button
                    class="setting-item"
                >
                    <div class="label">
                        {{ $t("Name") }}
                    </div>
                    <div class="value">
                        {{ onboardingUser.name }}
                    </div>
                </b-list-group-item>
                <b-list-group-item
                    @click="
                        $store.dispatch(
                            'setting/editOnboardingStep',
                            'dateOfBirth'
                        )
                    "
                    button
                    class="setting-item"
                >
                    <div class="label">
                        {{ $t("Age") }}
                    </div>
                    <div class="value">
                        {{ onboardingUser.age }}
                    </div>
                </b-list-group-item>
                <b-list-group-item
                    @click="
                        $store.dispatch('setting/editOnboardingStep', 'seeking')
                    "
                    button
                    class="setting-item"
                >
                    <div class="label">
                        {{ $t("Orientation") }}
                    </div>
                    <div class="value">
                        {{ onboardingUser.orientationLongForm }}
                    </div>
                </b-list-group-item>
                <b-list-group-item
                    @click="
                        $store.dispatch('setting/editOnboardingStep', 'story')
                    "
                    button
                    class="setting-item"
                >
                    <div class="label">
                        {{ $t("About me") }}
                    </div>
                    <div class="value">
                        <preserve-whitespace :content="onboardingUser.story" />
                    </div>
                </b-list-group-item>
                <b-list-group-item
                    @click="$store.dispatch('setting/editSetting', 'questions')"
                    button
                    class="setting-item"
                >
                    <div class="label">
                        {{ $t("Questions") }}
                    </div>
                    <div class="value">
                        <div
                            v-if="unansweredQuestionCount"
                            class="text-warning"
                        >
                            {{ unansweredQuestionCount }}
                            {{ $t("unanswered") }}
                        </div>
                        <div v-else-if="questions">
                            {{ questions.length }}
                            {{ $t("answered") }}
                        </div>
                    </div>
                </b-list-group-item>
                <b-list-group-item
                    v-if="onboardingUser.allowCustomLocation"
                    @click="
                        $store.dispatch('setting/editOnboardingStep', 'city')
                    "
                    button
                    class="setting-item"
                >
                    <div class="label">
                        {{ $t("Location") }}
                    </div>
                    <div class="value">
                        <div
                            class="small text-secondary d-flex align-items-center"
                        >
                            <img class="mr-1" :src="onboardingUser.flagUrl" />
                            {{ onboardingUser.countryName }}
                        </div>
                    </div>
                </b-list-group-item>
                <b-list-group-item
                    @click="
                        $store.dispatch(
                            'setting/editOnboardingStep',
                            'primaryLanguage'
                        )
                    "
                    button
                    class="setting-item"
                >
                    <div class="label">Language</div>
                    <div class="value">
                        <div
                            class="small text-secondary d-flex align-items-center"
                        >
                            {{ onboardingUser.primaryLanguage?.name }}
                        </div>
                    </div>
                </b-list-group-item>
            </b-list-group>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import AccountSectionTitle from "@/components/account/AccountSectionTitle";

export default {
    created() {
        this.$store.dispatch("questions/refresh");
    },
    computed: {
        firstSixPhotos() {
            return this.onboardingUser.photos.slice(0, 6);
        },
        ...mapState("questions", [
            "questions",
            "currentQuestion",
            "hasUnansweredQuestions",
            "unansweredQuestionCount",
        ]),
        ...mapState("user", ["premium"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        previewProfile() {
            this.$store.dispatch("profile/viewProfile", {
                userId: this.onboardingUser.id,
            });
        },
    },
    components: {
        AccountSectionTitle,
    },
};
</script>
