<template>
    <generic-page>
        <div class="p-2">
            <div class="d-flex align-items-center justify-content-between">
                <h5>
                    {{ $t("Subscribers") }}
                    <b-badge pill variant="light">
                        {{ subscriberCount }}
                    </b-badge>
                </h5>
                <div>
                    <div class="d-flex align-items-center border rounded-pill">
                        <b-badge
                            pill
                            variant="light"
                            class="d-flex align-items-center"
                        >
                            {{ $t("Subscription Price") }}
                            <div class="text-primary ml-2">
                                {{
                                    onboardingUser.subscriptionEarningsLocal
                                }}/week
                            </div>
                        </b-badge>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="subscriberCount" class="mt-3">
            <div
                @click="viewProfile(subscription.standardMap)"
                :key="subscription.standardMap.id"
                v-for="subscription in subscriptions"
                class="user-wrapper border-top border-light"
            >
                <user-standard-avatar
                    :nonPrimaryPhotos="false"
                    :hideBadges="true"
                    :userBetweenStatus="false"
                    :timestamp="subscription.created"
                    :standardMap="subscription.standardMap"
                    size="small"
                    class="border-light border-top"
                >
                    <div
                        class="d-flex align-items-center justify-content-center"
                    >
                        <div class="text-primary">
                            {{ subscription.price }}
                            {{ $t("per week") }}
                        </div>
                    </div>
                </user-standard-avatar>
            </div>
        </div>

        <b-alert variant="primary" show class="small my-3">
            <h5>
                {{ $t("How subscriptions work?") }}
            </h5>
            <div>
                <font-awesome-icon
                    icon="fa-solid fa-star"
                    class="text-warning"
                />
                {{
                    $t(
                        "Subscribers can send you unlimited messages for free as long as they continue to pay weekly"
                    )
                }}
            </div>
            <div class="mt-2">
                <font-awesome-icon
                    icon="fa-solid fa-star"
                    class="text-warning"
                />
                {{
                    $t(
                        "Subscribers can view your private posts, and pay you weekly for access"
                    )
                }}
            </div>
            <div class="mt-2">
                <font-awesome-icon
                    icon="fa-solid fa-star"
                    class="text-warning"
                />
                {{
                    $t(
                        "Their credit card will be charged every week, and your payout will be transferred to your wallet instantly"
                    )
                }}
            </div>
            <div class="mt-2">
                <font-awesome-icon
                    icon="fa-solid fa-star"
                    class="text-warning"
                />
                {{
                    $t(
                        "Post private content often to keep your subscribers interested, but also post publicly to attract new subscribers"
                    )
                }}
            </div>
            <div class="mt-2">
                <font-awesome-icon
                    icon="fa-solid fa-star"
                    class="text-warning"
                />
                {{
                    $t(
                        "Remember: you can also earn gifts from posting, even from your subscribers as well as from requests"
                    )
                }}
            </div>

            <creator-dashboard-url />
        </b-alert>
    </generic-page>
</template>

<script>
import { mapState } from "vuex";

import UserStandardAvatar from "@/components/user/UserStandardAvatar";
import CreatorDashboardUrl from "@/components/creator/invite/CreatorDashboardUrl";

export default {
    data() {
        return {
            subscriptions: null,
            subscriptionPrice: null,
            examples: Array.from(Array(100).keys()).unshift(),
        };
    },
    mounted() {
        this.subscriptionPrice = this.onboardingUser?.subscriptionPrice;

        this.refresh();
        this.$store.dispatch("creatorDashboard/refresh");
    },
    computed: {
        subscriberCount() {
            return this.subscriptions?.length;
        },
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        refresh() {
            this.api.post("/subscription/creator").then((response) => {
                if (response?.data?.subscriptions) {
                    this.subscriptions = response.data.subscriptions;
                }
            });
        },
        currentSubPrice(count) {
            return count * 5;
        },
        creditsPerWeek(count) {
            return [...Array(count).keys()]
                .map((i) => (i + 1) * 5)
                .reduce((partialSum, a) => partialSum + a, 0);
        },
        viewProfile(standardMap) {
            this.$store.dispatch("profile/viewProfile", {
                userId: standardMap.id,
                profile: standardMap,
            });
        },
    },
    components: {
        UserStandardAvatar,
        CreatorDashboardUrl,
    },
};
</script>