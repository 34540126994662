// this still had issues
const getRandomArbitrary = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);

    return Math.floor(Math.random() * (max - min + 1)) + min;
};

function uniqueFourDigitCode() {
    const min = 1000
    const max = 9999

    let difference = max - min;

    let rand = Math.random();

    rand = Math.floor(rand * difference);

    rand = rand + min;

    return rand;
}

function uniqueFourDigitCodeNonRepeating() {
    let digits = Array.from({ length: 10 }, (_, i) => i); // Array [0-9]
    let code = "";

    // Ensure the first digit is not zero
    code += digits.splice(Math.floor(Math.random() * 9) + 1, 1)[0];

    // Add the remaining 3 digits
    for (let i = 0; i < 3; i++) {
        code += digits.splice(Math.floor(Math.random() * digits.length), 1)[0];
    }

    return parseInt(code, 10); // Convert to integer for good measure
}

const getRandomString = (len, chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789') => [...Array(len)].map(() => chars.charAt(Math.floor(Math.random() * chars.length))).join('')

const shuffleArray = (deck) => {
    for (let i = deck.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [deck[i], deck[j]] = [deck[j], deck[i]];
    }

    return deck;
}

export {
    getRandomArbitrary,
    uniqueFourDigitCode,
    getRandomString,
    shuffleArray,
    uniqueFourDigitCodeNonRepeating
};
