<template>
    <div>
        <div v-if="price">
            <refill-package-small />

            <div class="mt-2">
                <div v-if="existingCard">
                    <refill-existing-card />
                </div>
                <div v-else>
                    <refill-create-card />
                </div>
            </div>
        </div>
        <div v-else>
            <refill-prices :prices="creditRefillPrices" />
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import RefillPrices from "@/components/wallet/refill/RefillPrices";
import RefillCreateCard from "@/components/wallet/refill/authorize/RefillCreateCard";
import RefillExistingCard from "@/components/wallet/refill/authorize/RefillExistingCard";
import RefillPackageSmall from "@/components/wallet/refill/RefillPackageSmall";

export default {
    computed: {
        existingCard() {
            return this.onboardingUser.authorizeProfile && !this.createCard;
        },
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
        ...mapState("options", ["creditRefillPrices", "prices"]),
        ...mapState("wallet", ["price", "createCard"]),
    },
    methods: {
        closeWallet() {
            this.$store.commit("interface/setUserWalletModalVisible", false);
        },
    },
    components: {
        RefillPrices,
        RefillCreateCard,
        RefillExistingCard,
        RefillPackageSmall,
    },
};
</script>