import api from "@/service/api";
import router from "@/router";

const defaultVisibility = 'public';

const state = () => ({
    page: 1,
    saving: false,
    hasMorePages: false,
    contentOffers: null,
    contentOffer: null,
    passed: null,
    error: null,
    days: null,
    message: null,
    contentSubmissions: null,
    insufficientCredits: false,
    setRecordingUploading: false,
    recording: false,
    schedulePostCount: null,
    visibility: defaultVisibility,
    uploading: false,
    publishing: false,
    recentlySaved: false,
})

const mutations = {
    reset(state) {
        state.error = null;
        state.message = null;
        state.passed = null;
        state.contentSubmissions = null;
        state.hasMorePages = false;
        state.setRecordingUploading = false;
        state.recording = false;
        state.saving = false;
        state.insufficientCredits = false
        state.visibility = defaultVisibility;
        state.page = 1;
        state.uploading = false;
        state.publishing = false;
        state.recentlySaved = false;
    },
    setMessage(state, value) {
        state.message = value
    },
    setContentSubmissions(state, value) {
        state.contentSubmissions = value;
    },
    setContentOffers(state, value) {
        state.contentOffers = value;
    },
    setContentOffer(state, value) {
        state.contentOffer = value;
    },
    setPassed(state, value) {
        state.passed = value;
    },
    setInsufficientCredits(state, value) {
        state.insufficientCredits = value;
    },
    setHasMorePages(state, value) {
        state.hasMorePages = value;
    },
    setRecentlySaved(state, value) {
        state.recentlySaved = value;
    },
    setRecording(state, value) {
        state.recording = value;
    },
    setRecordingUploading(state, value) {
        state.recordingUploading = value;
    },
    setVisibility(state, value) {
        state.visibility = value;
    },
    setDays(state, value) {
        state.days = value
    },
    setSchedulePostCount(state, value) {
        state.schedulePostCount = value
    },
    setSaving(state, value) {
        state.saving = value
    },
    setError(state, value) {
        state.error = value
    },
    nextPage(state) {
        state.page++;
    },
    previousPage(state) {
        state.page--;
    },
    setUploading(state, value) {
        state.uploading = value
    },
}

const actions = {
    nextPage({ commit, dispatch }) {
        commit('setContentOffers', null)
        commit('nextPage')
        dispatch('refresh')
    },
    previousPage({ commit, dispatch }) {
        commit('setContentOffers', null)
        commit('previousPage')
        dispatch('refresh')
    },
    refresh({ commit, state }) {
        api.post('/contentOffer/index', {
            page: state.page,
        }).then((response) => {
            if (response?.data?.contentOffers) {
                commit('setContentOffers', response.data.contentOffers)
                commit('setHasMorePages', response.data.hasMorePages)
                commit('setSchedulePostCount', response.data.schedulePostCount)
            }
        });
    },
    create({ dispatch, commit }, { publishedAt, isPrivate } = {}) {
        commit('setInsufficientCredits', false);

        api.post('/contentOffer/create', {
            publishedAt,
            isPrivate
        }).then((response) => {
            if (response?.data?.contentOffer) {
                dispatch('onboarding/status', null, { root: true })

                router.push({
                    name: 'contentOfferEdit',
                    params: {
                        id: response.data.contentOffer.id,
                    },
                })
            }
        }).catch(error => {
            if (error?.response?.data?.code === 'insufficientCredits') {
                commit('setInsufficientCredits', true);
            }
        })
    },
    get({ commit }, { contentOfferId, refresh } = {}) {
        if (!refresh) {
            commit('reset');
        }

        api.post(`/contentOffer/${contentOfferId}/get`).then((response) => {
            commit('setContentOffer', response?.data?.contentOffer)
            commit('setContentSubmissions', response?.data?.contentSubmissions)
            commit('setPassed', response?.data?.passed)
        });
    },
    refreshContentOffer({ state, dispatch }) {
        if (state.contentOffer) {
            dispatch('get', {
                contentOfferId: state.contentOffer.id,
                refresh: true
            })
        }
    },
    refreshSchedule({ commit }) {
        api.post('/contentOffer/schedule').then((response) => {
            if (response?.data?.days) {
                commit('setDays', response.data.days)
            }
        });
    },
    delete({ state }) {
        api.post(`/contentOffer/${state.contentOffer.id}/delete`).then(() => {
            router.push({ name: "contentOfferIndex" });
        })
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
