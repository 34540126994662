<template>
    <div>
        <div :class="`text-${progressVariant}`">
            {{ $t("Time Remaining") }}
            {{ displayCountdown }}
        </div>
        <b-progress :max="totalTime" height="5px" class="mt-1">
            <b-progress-bar :value="timeElapsed" :variant="progressVariant">
            </b-progress-bar>
        </b-progress>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            timeLeft: 0,
            intervalId: null,
        };
    },
    mounted() {
        this.initializeCountdown();
    },
    beforeDestroy() {
        // Clear the interval on component teardown
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    },
    computed: {
        ...mapState("creatorAuthenticity", ["creatorAuthenticity"]),

        // Total time in seconds between startedAt and deadlineAt
        totalTime() {
            const startedAt = new Date(this.creatorAuthenticity.startedAt);
            const deadlineAt = new Date(this.creatorAuthenticity.deadlineAt);
            return Math.floor((deadlineAt - startedAt) / 1000);
        },

        // Dynamic variant for the progress bar
        progressVariant() {
            if (this.timeLeft <= 30) {
                return "danger";
            } else if (this.timeLeft <= 60) {
                return "warning";
            }
            return "primary";
        },

        // Display format for the remaining time (HH:MM:SS)
        displayCountdown() {
            const hours = Math.floor(this.timeLeft / 3600);
            const minutes = Math.floor((this.timeLeft % 3600) / 60);
            const seconds = this.timeLeft % 60;

            // Build a short display string
            let display = "";
            if (hours > 0) display += `${hours}h `;
            if (minutes > 0) display += `${minutes}m `;
            display += `${seconds}s`;

            return display;
        },

        // How many seconds have elapsed since startedAt (for the progress bar value)
        timeElapsed() {
            return this.totalTime - this.timeLeft;
        },
    },
    methods: {
        initializeCountdown() {
            const now = new Date();
            const startedAt = new Date(this.creatorAuthenticity.startedAt);
            const elapsed = Math.floor((now - startedAt) / 1000);

            // Set initial timeLeft (if totalTime is negative or exhausted, set to 0)
            this.timeLeft = Math.max(this.totalTime - elapsed, 0);

            if (this.timeLeft === 0) {
                return;
            }

            // Update timeLeft every second
            this.intervalId = setInterval(() => {
                this.timeLeft--;
                if (this.timeLeft <= 0) {
                    // refresh on backend once done
                    // dont compare client vs. server timestamp
                    // assume their local clock can be out of sync
                    this.$store.dispatch("creatorAuthenticity/refresh");

                    this.timeLeft = 0;
                    clearInterval(this.intervalId);
                }
            }, 1000);
        },
    },
};
</script>
