import firebaseStorage from "@/utility/firebaseStorage"

import {
    ref as firebaseRef,
    onValue,
    onChildAdded,
    onChildRemoved,
    set,
    onDisconnect,
    child,
    off,
    remove,
} from 'firebase/database'

const state = () => ({
    enabled: false,
    onlineUserIds: [],
})

const getStorageKey = (userId) => {
    return `user-${userId}`
}

const mutations = {
    setEnabled(state, value) {
        state.enabled = value
    },
    addOnlineUserId(state, userId) {
        if (!state.onlineUserIds.includes(userId)) {
            state.onlineUserIds.push(userId)
        }
    },
    removeOnlineUserId(state, userId) {
        state.onlineUserIds = state.onlineUserIds.filter((id) => id !== userId)
    },
    setOnlineUserIds(state, userIds) {
        state.onlineUserIds = userIds
    },
}

const actions = {
    disable({ state, commit, rootState }) {
        if (!state.enabled || !firebaseStorage.presenceRef) {
            return
        }

        const userId = getStorageKey(rootState.onboarding.user.id)
        const userRef = child(firebaseStorage.presenceRef, userId)

        remove(userRef)

        off(firebaseStorage.presenceRef)
        firebaseStorage.presenceRef = null

        commit('setEnabled', false)
    },

    enable({ state, commit, rootState }) {
        if (state.enabled) {
            return
        }

        commit('setEnabled', true)

        const dbRef = firebaseStorage.db

        const environment = process.env.NODE_ENV;
        firebaseStorage.presenceRef = firebaseRef(dbRef, `globalOnlineStatus/${environment}`)

        onValue(firebaseStorage.presenceRef, (snapshot) => {
            const data = snapshot.val() || {}
            const onlineUserIds = Object.keys(data)
            commit('setOnlineUserIds', onlineUserIds)
        })

        onChildAdded(firebaseStorage.presenceRef, (snapshot) => {
            commit('addOnlineUserId', snapshot.key)
        })

        onChildRemoved(firebaseStorage.presenceRef, (snapshot) => {
            commit('removeOnlineUserId', snapshot.key)
        })

        if (!rootState.onboarding.user.stealthMode) {
            const userId = getStorageKey(rootState.onboarding.user.id)
            const userRef = child(firebaseStorage.presenceRef, userId)

            set(userRef, { userId })
            onDisconnect(userRef).remove()
        }
    },
}

const getters = {
    isUserOnline: (state) => (userId) => {
        const key = getStorageKey(userId);
        return state.onlineUserIds.includes(key)
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
