<template>
    <div>
        <a v-if="onboardingUser.countryIsoAlpha2 === 'PH'">
            <a class="btn btn-link" target="_blank" href="https://coins.ph">
                Coins.ph (Philippines)
            </a>
        </a>
        <a
            v-if="onboardingUser.countryIsoAlpha2 !== 'US'"
            class="btn btn-link"
            target="_blank"
            href="https://binance.com"
        >
            Binance
        </a>
        <a class="btn btn-link" target="_blank" href="https://coinbase.com">
            Coinbase
        </a>
        <a class="btn btn-link" target="_blank" href="https://kraken.com">
            Kraken
        </a>
        <a class="btn btn-link" target="_blank" href="https://crypto.com">
            Crypto.com
        </a>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
};
</script>