<template>
    <b-container class="bg-white">
        <b-row class="text-center">
            <b-col cols="6" class="py-4">
                <h1 class="text-success">
                    {{ balance }}
                </h1>
                <div class="lead">
                    <font-awesome-icon
                        class="small-avatar text-warning"
                        icon="fa-duotone fa-coins"
                        size="lg"
                    />
                    Credits
                </div>
            </b-col>
            <b-col cols="6" class="py-4">
                <h1 class="text-success">{{ balanceLocal }}</h1>
                <div class="lead">
                    {{ onboardingUser.currency }}
                </div>
            </b-col>
        </b-row>
        <div class="py-3">
            <b-btn
                to="/withdraw/index"
                variant="primary"
                pill
                block
                class="py-3"
                size="lg"
            >
                <div class="d-flex align-items-center justify-content-between">
                    {{ $t("Withdraw Money") }}
                    <font-awesome-icon icon="fa-light fa-chevron-right" />
                </div>
            </b-btn>
        </div>
    </b-container>
</template>


<script>
import { mapState } from "vuex";

export default {
    computed: {
        hasSubmittedWithdraw() {
            return this.currentWithdraw?.submitted;
        },
        hasDraftWithdraw() {
            return this.currentWithdraw?.id ? true : false;
        },
        ...mapState("withdraw", [
            "withdrawalError",
            "successMessage",
            "currentWithdraw",
        ]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
        balance() {
            return this.onboardingUser?.wallet?.balance;
        },
        balanceLocal() {
            return this.onboardingUser?.wallet?.balanceLocal;
        },
    },
    components: {},
};
</script>