<template>
    <div>
        <b-alert v-if="withdraw.submitted" show variant="success">
            <h5 class="d-flex align-items-center">
                {{ $t("Your money is on the way") }}
            </h5>

            <div class="mt-3">
                <coin-badge :amount="withdraw.balanceInt" />
                {{
                    $t(
                        "credits have been withdrawn from your wallet, and are now pending payout"
                    )
                }}
            </div>
            <div class="mt-3">
                {{
                    $t(
                        "We have been notified of the request, please allow up to 48 hours for processing. We will email you once your withdrawal has been processed"
                    )
                }}
            </div>

            <div class="small text-secondary mt-3">
                {{
                    $t(
                        "If you don't see an email after 48 hours be sure to check your spam folder, and be sure to mark the email 'Not Spam' so they are not flagged in the future"
                    )
                }}
            </div>

            <b-btn
                class="mt-3"
                @click="
                    $router.push({
                        name: 'withdrawView',
                        params: {
                            id: withdraw.id,
                        },
                    })
                "
                variant="primary"
                pill
            >
                {{ $t("View Details") }}
            </b-btn>
        </b-alert>
        <b-alert v-else variant="warning" show>
            <h5>
                {{ $t("Complete Your Withdrawal") }}
            </h5>
            <div class="small">
                <font-awesome-icon icon="fa-duotone fa-triangle-exclamation" />
                {{
                    $t(
                        "Your withdrawal has not been submitted yet, please complete all fields and submit it to withdraw your money"
                    )
                }}
            </div>

            <div>
                <b-btn
                    @click="
                        $router.push({
                            name: 'withdrawCreate',
                            params: {
                                id: withdraw.id,
                            },
                        })
                    "
                    variant="primary"
                    pill
                    class="mt-3"
                    size="lg"
                    block
                >
                    {{ $t("Finish Withdrawal") }}
                </b-btn>
            </div>

            <div>
                <b-btn
                    @click="cancelWithdraw"
                    variant="light"
                    pill
                    class="mt-3"
                    size="sm"
                    block
                >
                    {{ $t("Cancel and return") }}
                    <coin-badge :amount="withdraw.balanceInt" />
                    {{ $t("to wallet") }}
                </b-btn>
            </div>
        </b-alert>
    </div>
</template>


<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("withdraw", ["withdraw"]),
    },
    methods: {
        cancelWithdraw() {
            if (
                confirm(
                    "Are you sure you want to cancel this withdrawal? You will need to start over."
                )
            ) {
                this.$store.dispatch("withdraw/cancel");
            }
        },
    },
};
</script>