<template>
    <div>
        <div @click="apple" class="login-button bg-black">
            <div class="icon-block">
                <img
                    class="apple-icon-center"
                    src="https://d1lil0eivvt84o.cloudfront.net/icon/apple-white.svg"
                />
            </div>
            {{ $t("Continue with Apple") }}
            <div class="invisible icon-block">
                <img
                    class="apple-icon-center"
                    src="https://d1lil0eivvt84o.cloudfront.net/icon/apple-white.svg"
                />
            </div>
        </div>
    </div>
</template>

<script>
/* global FirebasePlugin, device */

import { mapGetters, mapState } from "vuex";
import { LAST_LOGIN_METHOD } from "@/constant/localStorage";

export default {
    data() {
        return {
            recaptchaSiteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
            lastLoginMethod: localStorage.getItem(LAST_LOGIN_METHOD),
        };
    },
    computed: {
        currentMode() {
            return this.$route.meta.mode;
        },
        actionLabel() {
            if (this.currentMode === "signup") {
                return this.$t("Signup");
            }

            return this.$t("Login");
        },
        ...mapState("login", ["method"]),
        ...mapState("system", ["cordova"]),
        ...mapGetters("user", ["isLoggedIn"]),
        enableAppleLogin() {
            if (!this.cordova) {
                return false;
            }

            if (device.platform !== "iOS") {
                return false;
            }

            if (this.lastLoginMethod && this.lastLoginMethod !== "apple") {
                return false;
            }

            return true;
        },
        enableEmailLogin() {
            return true;
        },
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        anonymousSignup() {
            this.api.post("/signup/anonymous").then((response) => {
                if (response?.data?.authToken) {
                    this.$store
                        .dispatch("user/login", response.data.authToken)
                        .then(() => {
                            this.$router.push({ name: "defaultPage" });
                        });
                }
            });
        },
        defaultPage() {
            this.$router.push({ name: "defaultPage" });
        },
        setLastLoginMethod(method) {
            localStorage.setItem(LAST_LOGIN_METHOD, method);
        },
        apple() {
            // cordova only
            FirebasePlugin.authenticateUserWithApple(
                (credential) => {
                    FirebasePlugin.signInWithCredential(
                        credential,
                        () => {
                            this.$store.dispatch(
                                "appEvent/create",
                                "login_success_apple"
                            );

                            this.loadCurrentUser();

                            this.setLastLoginMethod("apple");
                        },
                        (error) => {
                            this.$store.dispatch(
                                "appEvent/create",
                                "login_failure_apple"
                            );

                            console.error(error);
                        }
                    );
                },
                (error) => {
                    this.$store.dispatch(
                        "appEvent/create",
                        "login_failure_apple"
                    );

                    console.error(error);
                }
            );
        },
        loadCurrentUser() {
            FirebasePlugin.reloadCurrentUser(
                (firebaseUser) => {
                    this.loginFromIdToken(firebaseUser.idToken);
                },
                (error) => {
                    this.$store.dispatch(
                        "appEvent/create",
                        "login_reload_user_failure"
                    );
                    console.error(error);
                }
            );
        },
        loginFromIdToken(idToken) {
            this.api
                .post("/firebase/signinWithIdToken", {
                    idToken,
                })
                .then((response) => {
                    if (response?.data?.authToken) {
                        this.$store
                            .dispatch("user/login", response.data.authToken)
                            .then(() => {
                                this.$router.push({ name: "defaultPage" });
                            });
                    }
                });
        },
    },
    components: {},
};
</script>