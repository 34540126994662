import api from "@/service/api";

const state = () => ({
    reasons: [],
    genders: [],
    countries: [],
    setting: [],
    prohibitedCharacters: [],
    pusher: null,
    supabase: null,
    brand: null,
    deleteReasons: null,
    contactOptions: null,
    afaUsersAsian: null,
    photoViews: null,
    creatorReactions: null,
    creditExchangeRate: null,
    creatorEntryFeeWeeklyInvite: null,
    creditRefillOptions: null,
    payoutCoins: null,
    giftOptions: null,
    creditRefillPrices: null,
    creditRefillPricesCrypto: null,
    creditRefillPricesMinutes: null,
    expirationYears: null,
    expirationMonths: null,
    subscriptionCancelReasons: null,
    agora: null,
    prices: null,
    onesignal: null,
    creatorRequestDisputeReasons: null,
    rules: null,
    config: null,
    completedInitialLoad: false,
})

const mutations = {
    setCompletedInitialLoad(state, value) {
        state.completedInitialLoad = value
    },
    setOptions(state, options) {
        // be uber explicit about changing options
        // serves as the foundation of the app
        const {
            reasons,
            genders,
            countries,
            setting,
            prohibitedCharacters,
            pusher,
            brand,
            deleteReasons,
            contactOptions,
            afaUsersAsian,
            photoViews,
            creatorReactions,
            creditExchangeRate,
            creatorEntryFeeWeeklyInvite,
            creditRefillOptions,
            payoutCoins,
            giftOptions,
            creditRefillPrices,
            creditRefillPricesCrypto,
            expirationYears,
            expirationMonths,
            subscriptionCancelReasons,
            agora,
            creditRefillPricesMinutes,
            prices,
            onesignal,
            creatorRequestDisputeReasons,
            rules,
            config,
            supabase,
        } = options;

        if (creatorRequestDisputeReasons) {
            state.creatorRequestDisputeReasons = creatorRequestDisputeReasons
        }

        if (rules) {
            state.rules = rules
        }

        if (config) {
            state.config = config
        }

        if (supabase) {
            state.supabase = supabase
        }

        if (onesignal) {
            state.onesignal = onesignal;
        }

        if (subscriptionCancelReasons) {
            state.subscriptionCancelReasons = subscriptionCancelReasons;
        }

        if (prices) {
            state.prices = prices;
        }

        if (creditRefillPricesMinutes) {
            state.creditRefillPricesMinutes = creditRefillPricesMinutes;
        }

        if (agora) {
            state.agora = agora;
        }

        if (giftOptions) {
            state.giftOptions = giftOptions;
        }

        if (creditRefillPrices) {
            state.creditRefillPrices = creditRefillPrices;
        }

        if (creditRefillPricesCrypto) {
            state.creditRefillPricesCrypto = creditRefillPricesCrypto;
        }

        if (payoutCoins) {
            state.payoutCoins = payoutCoins;
        }

        if (creditRefillOptions) {
            state.creditRefillOptions = creditRefillOptions;
        }

        if (reasons) {
            state.reasons = reasons;
        }

        if (genders) {
            state.genders = genders;
        }

        if (countries) {
            state.countries = countries;
        }

        if (setting) {
            state.setting = setting;
        }

        if (creditExchangeRate) {
            state.creditExchangeRate = creditExchangeRate;
        }

        if (creatorEntryFeeWeeklyInvite) {
            state.creatorEntryFeeWeeklyInvite = creatorEntryFeeWeeklyInvite;
        }

        if (prohibitedCharacters) {
            state.prohibitedCharacters = prohibitedCharacters;
        }

        if (pusher) {
            state.pusher = pusher;
        }

        if (brand) {
            state.brand = brand;
        }

        if (deleteReasons) {
            state.deleteReasons = deleteReasons;
        }

        if (contactOptions) {
            state.contactOptions = contactOptions;
        }

        if (afaUsersAsian) {
            state.afaUsersAsian = afaUsersAsian;
        }

        if (photoViews) {
            state.photoViews = photoViews;
        }

        if (creatorReactions) {
            state.creatorReactions = creatorReactions;
        }

        if (expirationYears) {
            state.expirationYears = expirationYears;
        }

        if (expirationMonths) {
            state.expirationMonths = expirationMonths;
        }
    },
}

const actions = {
    refresh({ commit }) {
        return api.post("/options/get").then((response) => {
            if (response?.data?.options) {
                commit('setOptions', response.data.options)
                commit('setCompletedInitialLoad', true)
            }
        })
    },
}

const getters = {
    getCreatorDisputeReason: (state) => (reason) => {
        return state.creatorRequestDisputeReasons?.find(creatorRequestDisputeReason => creatorRequestDisputeReason.reason === reason)
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
}
