<template>
    <div>
        <div v-if="price">
            <how-crypto-works />

            <b-btn
                :disabled="saving"
                class="mt-3"
                @click="createInvoice"
                variant="primary"
                size="lg"
                block
                pill
            >
                Pay with Crypto
            </b-btn>
        </div>
        <div v-else>
            <refill-prices :prices="creditRefillPricesCrypto" />
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import HowCryptoWorks from "@/components/utility/HowCryptoWorks";
import RefillPrices from "@/components/wallet/refill/RefillPrices";

export default {
    data() {
        return {
            saving: false,
        };
    },
    computed: {
        ...mapState("wallet", ["price"]),
        ...mapState("options", ["creditRefillPricesCrypto"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        selectPackage(price) {
            this.$store.commit("wallet/setPrice", price);
        },
        createInvoice() {
            this.saving = true;

            this.api
                .post("/nowpayments/create", {
                    priceId: this.price.id,
                })
                .then((response) => {
                    if (response?.data?.nowpaymentsInvoice?.invoiceUrl) {
                        document.location =
                            response?.data?.nowpaymentsInvoice?.invoiceUrl;
                    }
                })
                .catch(() => {})
                .then(() => {
                    this.saving = false;
                });
        },
    },
    components: {
        HowCryptoWorks,
        RefillPrices,
    },
};
</script>