<template>
    <generic-page>
        <div class="d-flex align-items-center justify-content-between">
            <b-btn
                variant="link"
                size="lg"
                class="pl-0"
                to="/creator/contentOffer/index"
            >
                <font-awesome-icon size="lg" icon="fa-light fa-chevron-left" />
                {{ $t("All Content Offers") }}
            </b-btn>

            <b-btn @click="modal = true" variant="light" pill>
                <font-awesome-icon icon="fa-duotone fa-info-circle" />
                {{ $t("Help") }}
            </b-btn>
        </div>

        <b-modal
            :title="$t('Content Help')"
            v-model="modal"
            hide-footer
            centered
        >
            <content-offer-explainer />
        </b-modal>

        <div v-if="contentOffer">
            <div class="bg-white p-3">
                <h5>
                    <font-awesome-icon
                        class="small-avatar branded"
                        icon="fa-duotone fa-photo-film"
                        size="lg"
                    />
                    {{ contentOffer.title }}
                </h5>

                {{ contentOffer.description }}

                <div class="mt-3">
                    <b-dropdown
                        v-if="passed"
                        variant="light"
                        toggle-class="bg-light rounded-pill border-0 opacity-50"
                    >
                        <template #button-content>
                            <font-awesome-icon icon="fa-light fa-check" />
                            {{ $t("Not Interested") }}
                        </template>

                        <b-dropdown-item @click="unpass">
                            {{ $t("I changed my mind, I'm interested") }}
                        </b-dropdown-item>
                    </b-dropdown>
                    <b-btn v-else variant="secondary" pill @click="pass">
                        <font-awesome-icon icon="fa-duotone fa-thumbs-down" />
                        {{ $t("Not Interested") }}
                    </b-btn>
                </div>

                <div class="mt-3 border-top pt-3">
                    <coin-badge label="Reward" :amount="contentOffer.reward" />
                    <small class="text-secondary">
                        {{
                            $t(
                                "Only the top content will be rewarded, make sure you are looking your best, and creating high quality content"
                            )
                        }}
                    </small>
                </div>
            </div>

            <h5 class="mt-3">{{ $t("Submissions") }}</h5>
            <content-offer-submissions />
        </div>
        <div v-else>
            <generic-loading />
        </div>
    </generic-page>
</template>

<script>
import { mapState } from "vuex";

import ContentOfferSubmissions from "@/components/creator/contentOffer/ContentOfferSubmissions";
import ContentOfferExplainer from "@/components/creator/contentOffer/ContentOfferExplainer";

export default {
    data() {
        return {
            modal: false,
        };
    },
    created() {
        if (this.$route.params?.id) {
            this.$store.dispatch("contentOffer/get", {
                contentOfferId: this.$route.params?.id,
            });
        }
    },
    watch: {
        $route(to) {
            if (to?.params?.id !== this.contentOffer?.id) {
                this.$store.dispatch("contentOffer/get", {
                    contentOfferId: this.$route.params?.id,
                });
            }
        },
    },
    computed: {
        ...mapState("options", ["config"]),
        ...mapState("contentOffer", ["contentOffer", "passed"]),
    },
    methods: {
        async unpass() {
            await this.api.post(`/contentOffer/${this.contentOffer.id}/unpass`);
            this.$store.dispatch("contentOffer/refreshContentOffer");
        },
        async pass() {
            await this.api.post(`/contentOffer/${this.contentOffer.id}/pass`);
            this.$router.push({ name: "contentOfferIndex" });
        },
    },
    components: {
        ContentOfferSubmissions,
        ContentOfferExplainer,
    },
};
</script>