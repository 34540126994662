<template>
    <div>
        <font-awesome-icon
            size="5x"
            class="text-success"
            icon="fa-light fa-check-circle"
        />
        <div class="mt-1 lead">
            {{ $t("Your request was sent") }}
        </div>

        <div class="mt-3">
            <b-btn @click="closeModal" variant="primary" size="lg" block pill>
                {{ $t("Close") }}
            </b-btn>
            <b-btn
                @click="viewAllRequests"
                variant="light"
                size="lg"
                block
                pill
                class="mt-3"
            >
                {{ $t("View all requests") }}
            </b-btn>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        closeModal() {
            this.$store.commit(
                "interface/setCreatorRequestModalVisible",
                false
            );
        },
        viewAllRequests() {
            this.closeModal();
            this.$router.push({ name: "accountCreatorRequests" });
        },
    },
};
</script>