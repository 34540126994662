<template>
    <div>
        <div v-if="onboardingUser.hasVerificationVideo">
            <onboarding-verification-complete />
        </div>
        <div v-else-if="code">
            <onboarding-verification-prompt :code="code" />

            <onboarding-verification-record class="my-3" :code="code" />

            <div v-if="!recording && !recordingUploading" class="mt-3">
                <onboarding-verification-upload :code="code" />
            </div>

            <div v-if="!recording && !recordingUploading" class="mt-3">
                <onboarding-verification-link />

                <onboarding-verification-skip class="mt-3">
                    <b-btn variant="light" size="sm" pill> Skip video </b-btn>
                </onboarding-verification-skip>
            </div>
        </div>
        <div v-else>
            <generic-loading />
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import OnboardingVerificationComplete from "@/components/onboarding/verification/OnboardingVerificationComplete";
import OnboardingVerificationRecord from "@/components/onboarding/verification/OnboardingVerificationRecord";
import OnboardingVerificationUpload from "@/components/onboarding/verification/OnboardingVerificationUpload";
import OnboardingVerificationLink from "@/components/onboarding/verification/OnboardingVerificationLink";
import OnboardingVerificationPrompt from "@/components/onboarding/verification/OnboardingVerificationPrompt";
import OnboardingVerificationSkip from "@/components/onboarding/verification/OnboardingVerificationSkip";

export default {
    mounted() {
        this.refresh();
    },
    data() {
        return {
            code: null,
        };
    },
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
        ...mapState("system", ["cordova"]),
        ...mapState("verification", ["recording", "recordingUploading"]),
    },
    methods: {
        refresh() {
            this.api.post(`/video/code`).then((response) => {
                this.code = response?.data?.code;
            });
        },
    },
    components: {
        OnboardingVerificationComplete,
        OnboardingVerificationRecord,
        OnboardingVerificationUpload,
        OnboardingVerificationLink,
        OnboardingVerificationPrompt,
        OnboardingVerificationSkip,
    },
};
</script>