<template>
    <div>
        <b-list-group>
            <b-list-group-item
                :to="`/creator/contentSubmission/view/${contentSubmission.id}`"
                :key="contentSubmission.id"
                v-for="contentSubmission in contentSubmissions"
                action
            >
                View Submission {{ contentSubmission.id }}
            </b-list-group-item>
        </b-list-group>

        <div class="bg-light p-3 text-center border py-5">
            <div>Keep the video short. 30-60 seconds is recommended.</div>

            <content-offer-upload :contentOffer="contentOffer" />
            <content-offer-record :contentOffer="contentOffer" class="mt-3" />
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import ContentOfferUpload from "@/components/creator/contentOffer/ContentOfferUpload";
import ContentOfferRecord from "@/components/creator/contentOffer/ContentOfferRecord";

export default {
    computed: {
        ...mapState("options", ["config"]),
        ...mapState("contentOffer", [
            "contentOffer",
            "contentSubmissions",
            "passed",
        ]),
    },
    methods: {},
    components: {
        ContentOfferRecord,
        ContentOfferUpload,
    },
};
</script>