<template>
    <div>
        <h5 class="mt-3 mb-0">
            {{ $t("Your authenticity code") }}
        </h5>
        <h1 class="text-primary display-2">{{ creatorAuthenticity.code }}</h1>

        <authenticity-deadline :key="creatorAuthenticity.id" />

        <b-table-simple bordered class="text-left mt-3">
            <tbody>
                <tr>
                    <th class="px-3 bg-light" style="width: 1%">
                        <font-awesome-icon
                            icon="fa-light fa-check-circle"
                            class="text-success"
                        />
                    </th>
                    <td class="bg-white">
                        {{ $t("Your face must be clearly showing") }}
                    </td>
                </tr>
                <tr>
                    <th class="px-3 bg-light" style="width: 1%">
                        <font-awesome-icon
                            icon="fa-light fa-check-circle"
                            class="text-success"
                        />
                    </th>
                    <td class="bg-white">
                        {{ $t("Record a short video saying") }}
                        "{{ creatorAuthenticity.code }}"
                    </td>
                </tr>
                <tr>
                    <th class="px-3 bg-light" style="width: 1%">
                        <font-awesome-icon
                            icon="fa-light fa-check-circle"
                            class="text-success"
                        />
                    </th>
                    <td class="bg-white">
                        {{ $t("Keep it short, less than") }}
                        {{ rules.profileVideoMaxDuration }}
                        {{ $t("seconds") }}
                    </td>
                </tr>
            </tbody>
        </b-table-simple>

        <div v-if="recording">
            <div class="d-flex align-items-center justify-content-between">
                <div class="text-danger">
                    <b-spinner small type="grow" variant="danger" />
                    <strong> {{ $t("Recording") }} </strong>
                </div>

                <second-timer class="text-danger" v-if="timer" />
            </div>

            <video
                ref="video"
                class="img-fluid"
                playsinline
                autoplay
                muted
            ></video>

            <b-btn
                @click="stopRecording"
                pill
                variant="primary"
                size="lg"
                block
                class="mt-3"
            >
                {{ $t("Finished Recording") }}
            </b-btn>
        </div>
        <div v-else-if="recordingUploading">
            <generic-loading label="Uploading" />
        </div>
        <div v-else-if="creatorAuthenticity.deadlinePassed">
            <b-btn
                @click="next"
                pill
                variant="primary"
                size="lg"
                block
                class="mt-3"
            >
                {{ $t("Continue") }}
            </b-btn>
        </div>
        <div v-else>
            <b-alert v-if="exceededDurationLimit" variant="danger" show>
                {{ $t("The submitted video is longer than the limit of") }}
                {{ config.systemRuleProfileVideoMaxDuration }}
                {{ $t("seconds") }}
            </b-alert>
            <b-btn
                @click="startRecording"
                pill
                variant="primary"
                size="lg"
                block
                class="mt-3"
            >
                <font-awesome-icon icon="fa-duotone fa-camcorder" />
                {{ $t("Record Video") }}
            </b-btn>
        </div>
    </div>
</template>


<style scoped>
#upload_widget_container {
    position: relative;
}
</style>


<script>
import { mapState } from "vuex";

import SecondTimer from "@/components/utility/SecondTimer";
import AuthenticityDeadline from "@/components/onboarding/authenticity/AuthenticityDeadline";

export default {
    data() {
        return {
            exceededDurationLimit: false,
            timer: false,
            stream: null,
            mediaRecorder: null,
            recordedChunks: [],
        };
    },
    beforeDestroy() {
        if (this.stream) {
            this.stream.getTracks().forEach((track) => track.stop());
        }
    },
    computed: {
        pastDeadline() {
            return new Date() > new Date(this.creatorAuthenticity.deadlineAt);
        },
        ...mapState("options", ["config", "rules"]),
        ...mapState("creatorAuthenticity", [
            "recording",
            "recordingUploading",
            "creatorAuthenticity",
        ]),
    },
    methods: {
        next() {
            this.$store.dispatch("onboarding/status");
        },
        async startRecording() {
            this.$store.commit("creatorAuthenticity/setRecording", true);

            this.stream = await navigator.mediaDevices.getUserMedia({
                video: true,
                audio: true,
            });

            this.timer = true;

            this.$refs.video.srcObject = this.stream;

            // original
            const options = {
                // mimeType: "video/mp4",
            };

            this.mediaRecorder = new MediaRecorder(this.stream, options);

            this.mediaRecorder.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    this.recordedChunks.push(event.data);
                }
            };
            this.mediaRecorder.start();
        },
        stopRecording() {
            this.mediaRecorder.stop();

            this.mediaRecorder.onstop = () => {
                const blob = new Blob(this.recordedChunks, {
                    type: "video/mp4",
                });

                this.uploadCloudinary(blob);
            };

            // Stop all tracks to end the stream
            this.stream.getTracks().forEach((track) => track.stop());

            this.$store.commit("creatorAuthenticity/setRecording", false);
        },
        uploadCloudinary(blob) {
            this.$store.commit(
                "creatorAuthenticity/setRecordingUploading",
                true
            );

            const formData = new FormData();

            formData.append("file", blob);
            formData.append("upload_preset", "w9g8lnht");

            this.exceededDurationLimit = false;

            fetch(`https://api.cloudinary.com/v1_1/findmateapp/video/upload`, {
                method: "POST",
                body: formData,
            })
                .then((response) => response.json())
                .then((data) => {
                    if (
                        data.duration >
                        this.config.systemRuleProfileVideoMaxDuration
                    ) {
                        this.$store.commit(
                            "creatorAuthenticity/setRecordingUploading",
                            false
                        );
                        this.exceededDurationLimit = true;
                        return;
                    }

                    this.api
                        .post("/video/uploadAuthenticity", {
                            url: data.url,
                            creatorAuthenticityId: this.creatorAuthenticity.id,
                        })
                        .then(() => {
                            this.$store.commit(
                                "creatorAuthenticity/setRecordingUploading",
                                false
                            );

                            this.$store.dispatch("onboarding/status");
                        });
                })
                .catch((error) => console.error(error));
        },
    },
    components: {
        SecondTimer,
        AuthenticityDeadline,
    },
};
</script>