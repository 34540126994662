import api from "@/service/api";

const state = () => ({
    errorCode: null,
    purchaseInProgress: null,
    transactionView: 'default',
    transactions: null,
    price: null,
    purchaseComplete: false,
    createCard: false,
    addingCredits: false,
    legacyWallet: false,
    refreshingTransactionHistory: false,
    method: null,
})

const mutations = {
    reset(state) {
        state.method = null
        state.price = null
        state.errorCode = null
        state.purchaseComplete = false
        state.createCard = false
        state.addingCredits = false
        state.refreshingTransactionHistory = false
    },
    setLegacyWallet(state, value) {
        state.legacyWallet = value
    },
    setMethod(state, value) {
        state.method = value
    },
    setErrorCode(state, value) {
        state.errorCode = value
    },
    setPurchaseInProgress(state, value) {
        state.purchaseInProgress = value
    },
    setTransactions(state, value) {
        state.transactions = value
    },
    setPurchaseComplete(state, value) {
        state.purchaseComplete = value
    },
    setPrice(state, value) {
        state.price = value
    },
    setCreateCard(state, value) {
        state.createCard = value
    },
    setAddingCredits(state, value) {
        state.addingCredits = value
    },
    setRefreshingTransactionHistory(state, value) {
        state.refreshingTransactionHistory = value
    },
}

const knownHttpExceptionCodes = [
    'notEnoughCredits',
    'noPaymentMethod',
    'paymentFailed',
    'insufficientBalance',
]

const actions = {
    view({ commit }) {
        commit("interface/setUserWalletModalVisible", true, { root: true });
    },
    handleError({ commit }, error) {
        commit('reset')

        if (knownHttpExceptionCodes.includes(error?.response?.data?.code)) {
            commit('setErrorCode', error?.response?.data?.code)
            commit('interface/setUserWalletModalVisible', true, { root: true })
        }
    },
    buyCredit({ commit, dispatch }, { refillOptionId }) {
        commit('setPurchaseInProgress', true)

        api
            .post("/credit/buy", {
                refillOptionId
            })
            .then(() => {
                commit('setPurchaseComplete', true)
                dispatch("onboarding/status", null, { root: true });
            })
            .catch(() => { })
            .then(() => {
                commit('setPurchaseInProgress', false)
            })
    },
    refreshTransactionHistory({ commit, state }) {
        commit("setRefreshingTransactionHistory", true)

        api
            .post("/transaction/index", {
                view: state.transactionView,
            })
            .then((response) => {
                if (response?.data?.transactions) {
                    commit("setTransactions", response.data.transactions);
                }

                if (response?.data?.legacyWallet) {
                    commit("setLegacyWallet", response?.data?.legacyWallet);
                }
            }).finally(() => {
                commit("setRefreshingTransactionHistory", false)
            })
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
