<template>
    <div>
        <div v-if="withdrawals?.length">
            <h5>
                {{ $t("Withdrawal History") }}
            </h5>
            <b-table-simple striped>
                <thead>
                    <th>{{ $t("Credits") }}</th>
                    <th>{{ $t("Local") }}</th>
                    <th>{{ $t("Date") }}</th>
                    <th></th>
                </thead>
                <tbody>
                    <tr :key="withdrawal.id" v-for="withdrawal in withdrawals">
                        <td>
                            <coin-badge :amount="withdrawal.amount" />
                        </td>
                        <td class="small">~{{ withdrawal.local }}</td>
                        <td class="small">
                            {{ withdrawal.fulfilledAtRelative }}
                        </td>
                        <td class="small">
                            <b-btn
                                size="sm"
                                @click="
                                    $router.push({
                                        name: 'withdrawView',
                                        params: {
                                            id: withdrawal.id,
                                        },
                                    })
                                "
                                variant="primary"
                                pill
                            >
                                {{ $t("View") }}
                            </b-btn>
                        </td>
                    </tr>
                </tbody>
            </b-table-simple>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            withdrawals: null,
        };
    },
    mounted() {
        this.api.post("/withdraw/index").then((response) => {
            this.withdrawals = response.data.withdrawals;
        });
    },
};
</script>