<template>
    <b-list-group>
        <b-list-group-item
            to="/earnings/currency"
            class="d-flex align-items-center justify-content-between"
        >
            <div>
                Change Currency
                <b-badge variant="light" pill>
                    {{ onboardingUser.currency }}
                </b-badge>
            </div>
            <font-awesome-icon size="lg" icon="fa-light fa-chevron-right" />
        </b-list-group-item>
        <b-list-group-item
            to="/earnings/calculator"
            class="d-flex align-items-center justify-content-between"
        >
            Credit Conversion Calculator
            <font-awesome-icon size="lg" icon="fa-light fa-chevron-right" />
        </b-list-group-item>
        <b-list-group-item
            to="/earnings/history"
            class="d-flex align-items-center justify-content-between"
        >
            Global Earning History
            <font-awesome-icon size="lg" icon="fa-light fa-chevron-right" />
        </b-list-group-item>
    </b-list-group>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
};
</script>