<template>
    <div>
        <b-modal
            :title="$t('Wallet')"
            hide-footer
            v-model="modal"
            centered
            no-close-on-esc
            scrollable
            static
        >
            <div v-if="addMoney">
                <wallet-navigation />
                <wallet-error />

                <div>
                    <refill-credit v-if="onboardingUser.newBilling" />
                    <refill-credit-legacy v-else />
                </div>
            </div>
            <div v-else>
                <div class="d-flex align-items-center justify-content-between">
                    <div>
                        <wallet-balance />
                    </div>

                    <div v-if="onboardingUser.allowPayments">
                        <b-btn
                            @click="
                                $store.commit('wallet/setAddingCredits', true)
                            "
                            variant="primary"
                            pill
                        >
                            {{ $t("Add Credits") }}
                        </b-btn>
                    </div>
                </div>
                <div class="mt-3">
                    <transaction-history v-if="modal" />
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { mapState } from "vuex";

import TransactionHistory from "@/components/wallet/TransactionHistory";
import RefillCredit from "@/components/wallet/RefillCredit";
import RefillCreditLegacy from "@/components/wallet/RefillCreditLegacy";
import WalletBalance from "@/components/utility/WalletBalance";
import WalletNavigation from "@/components/wallet/WalletNavigation";
import WalletError from "@/components/wallet/WalletError";

export default {
    computed: {
        modal: {
            get() {
                return this.$store.state.interface.userWalletModalVisible;
            },
            set(value) {
                this.$store.commit(
                    "interface/setUserWalletModalVisible",
                    value
                );
            },
        },
        addMoney() {
            if (this.addingCredits) {
                return true;
            }

            if (this.errorCode) {
                return true;
            }

            return false;
        },
        hasPaymentMethod() {
            if (this.errorCode === "paymentFailed") {
                return false;
            }

            if (this.onboardingUser.hasPaymentMethod) {
                return true;
            }

            return false;
        },
        ...mapState("wallet", ["errorCode", "addingCredits"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
        balance() {
            return this.onboardingUser?.wallet?.balance;
        },
    },
    components: {
        WalletBalance,
        RefillCredit,
        RefillCreditLegacy,
        TransactionHistory,
        WalletNavigation,
        WalletError,
    },
};
</script>