<template>
    <generic-page>
        <b-btn variant="link" size="lg" class="pl-0" to="/earnings">
            <font-awesome-icon size="lg" icon="fa-light fa-chevron-left" />
            {{ $t("Back") }}
        </b-btn>

        <div v-if="isRestricted">
            <entry-fee />
        </div>
        <div v-else>
            <withdraw-types />
        </div>

        <withdraw-history class="mt-3" />
    </generic-page>
</template>

<script>
import { mapGetters } from "vuex";

import WithdrawTypes from "@/components/withdraw/WithdrawTypes";
import WithdrawHistory from "@/components/withdraw/WithdrawHistory";
import EntryFee from "@/components/creator/entryFee/EntryFee";

export default {
    computed: {
        ...mapGetters("creator", ["isRestricted"]),
    },
    components: {
        WithdrawTypes,
        WithdrawHistory,
        EntryFee,
    },
};
</script>