<template>
    <div>
        <b-alert variant="warning" show>
            <h5>
                {{ $t("You have unpaid fines") }}
            </h5>
            <div>
                {{
                    $t(
                        "You must pay off all of your fines to be eligible to withdraw"
                    )
                }}
            </div>
            <div class="mt-3">
                {{
                    $t(
                        "Click Pay Fines to cancel the current withdraw, which will return credits to your wallet so you can pay the fines"
                    )
                }}
            </div>
            <div class="mt-3">
                {{
                    $t(
                        "Once the fines are paid, you can submit a new withdrawal"
                    )
                }}
            </div>

            <b-btn
                @click="payFines"
                variant="primary"
                block
                pill
                size="lg"
                class="mt-3"
            >
                {{ $t("Pay Fines") }}
            </b-btn>
        </b-alert>
    </div>
</template>

<script>
export default {
    methods: {
        payFines() {
            this.$store.dispatch("withdraw/cancel", { withoutRedirect: true });
            this.$router.push("/creator/fines");
        },
    },
};
</script>