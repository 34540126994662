var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.withdraw.submitted)?_c('b-alert',{attrs:{"show":"","variant":"success"}},[_c('h5',{staticClass:"d-flex align-items-center"},[_vm._v(" "+_vm._s(_vm.$t("Your money is on the way"))+" ")]),_c('div',{staticClass:"mt-3"},[_c('coin-badge',{attrs:{"amount":_vm.withdraw.balanceInt}}),_vm._v(" "+_vm._s(_vm.$t( "credits have been withdrawn from your wallet, and are now pending payout" ))+" ")],1),_c('div',{staticClass:"mt-3"},[_vm._v(" "+_vm._s(_vm.$t( "We have been notified of the request, please allow up to 48 hours for processing. We will email you once your withdrawal has been processed" ))+" ")]),_c('div',{staticClass:"small text-secondary mt-3"},[_vm._v(" "+_vm._s(_vm.$t( "If you don't see an email after 48 hours be sure to check your spam folder, and be sure to mark the email 'Not Spam' so they are not flagged in the future" ))+" ")]),_c('b-btn',{staticClass:"mt-3",attrs:{"variant":"primary","pill":""},on:{"click":function($event){return _vm.$router.push({
                    name: 'withdrawView',
                    params: {
                        id: _vm.withdraw.id,
                    },
                })}}},[_vm._v(" "+_vm._s(_vm.$t("View Details"))+" ")])],1):_c('b-alert',{attrs:{"variant":"warning","show":""}},[_c('h5',[_vm._v(" "+_vm._s(_vm.$t("Complete Your Withdrawal"))+" ")]),_c('div',{staticClass:"small"},[_c('font-awesome-icon',{attrs:{"icon":"fa-duotone fa-triangle-exclamation"}}),_vm._v(" "+_vm._s(_vm.$t( "Your withdrawal has not been submitted yet, please complete all fields and submit it to withdraw your money" ))+" ")],1),_c('div',[_c('b-btn',{staticClass:"mt-3",attrs:{"variant":"primary","pill":"","size":"lg","block":""},on:{"click":function($event){return _vm.$router.push({
                        name: 'withdrawCreate',
                        params: {
                            id: _vm.withdraw.id,
                        },
                    })}}},[_vm._v(" "+_vm._s(_vm.$t("Finish Withdrawal"))+" ")])],1),_c('div',[_c('b-btn',{staticClass:"mt-3",attrs:{"variant":"light","pill":"","size":"sm","block":""},on:{"click":_vm.cancelWithdraw}},[_vm._v(" "+_vm._s(_vm.$t("Cancel and return"))+" "),_c('coin-badge',{attrs:{"amount":_vm.withdraw.balanceInt}}),_vm._v(" "+_vm._s(_vm.$t("to wallet"))+" ")],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }