<template>
    <div>
        <div v-if="method === 'authorize'">
            <refill-authorize />
        </div>
        <div v-if="method === 'crypto'">
            <refill-crypto />
        </div>

        <div v-if="!method">
            <h5 class="text-center mt-3">
                {{ $t("Select Payment Method") }}
            </h5>

            <b-btn
                v-if="!onboardingUser.cardPurchaseDisabled"
                @click="setMethod('authorize')"
                variant="light"
                block
                size="lg"
                class="mt-3 d-flex align-items-center justify-content-between btn-brand"
            >
                <div class="d-flex align-items-center text-left">
                    <font-awesome-icon
                        icon="fa-duotone fa-credit-card"
                        class="text-primary mr-3"
                        size="lg"
                    />
                    <div>
                        <h5 class="mb-0">
                            {{ $t("Credit / Debit Card") }}
                        </h5>
                        <div class="small text-secondary">
                            {{ $t("VISA, Mastercard") }}
                        </div>
                    </div>
                </div>
                <font-awesome-icon icon="fa-light fa-chevron-right" />
            </b-btn>

            <div
                v-if="
                    !onboardingUser.cardPurchaseDisabled &&
                    onboardingUser.alternativePaymentUrl
                "
            >
                <b-btn
                    :href="onboardingUser.alternativePaymentUrl"
                    target="_blank"
                    variant="light"
                    block
                    size="lg"
                    class="mt-3 d-flex align-items-center justify-content-between btn-brand"
                >
                    <div class="d-flex align-items-center text-left">
                        <font-awesome-icon
                            icon="fa-duotone fa-credit-card"
                            class="text-primary mr-3"
                            size="lg"
                        />
                        <div>
                            <h5 class="mb-0">
                                {{ $t("Alternative Payment Service") }}
                            </h5>
                            <div class="small text-secondary">
                                {{ $t("Backup Processor") }}
                            </div>
                        </div>
                    </div>
                    <font-awesome-icon icon="fa-light fa-chevron-right" />
                </b-btn>
            </div>

            <b-btn
                target="_blank"
                variant="light"
                block
                size="lg"
                class="mt-3 d-flex align-items-center justify-content-between btn-brand"
                @click="setMethod('crypto')"
            >
                <div class="d-flex align-items-center text-left">
                    <font-awesome-icon
                        icon="fa-brands fa-bitcoin"
                        class="text-primary mr-3"
                        size="lg"
                    />
                    <div>
                        <h5 class="mb-0">
                            {{ $t("Cryptocurrency") }}
                        </h5>
                        <div class="small text-secondary">
                            {{ $t("Bitcoin, and more") }}
                        </div>
                    </div>
                </div>
                <font-awesome-icon icon="fa-light fa-chevron-right" />
            </b-btn>

            <refill-credit-explainer class="mt-4" />
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import RefillAuthorize from "@/components/wallet/refill/RefillAuthorize";
import RefillCrypto from "@/components/wallet/refill/RefillCrypto";
import RefillCreditExplainer from "@/components/wallet/refill/RefillCreditExplainer";

export default {
    computed: {
        ...mapState("wallet", ["method"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        setMethod(method) {
            this.$store.commit("wallet/setMethod", method);
        },
    },
    components: {
        RefillCreditExplainer,
        RefillCrypto,
        RefillAuthorize,
    },
};
</script>