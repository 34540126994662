<template>
    <div>
        <div class="p-2 bg-light rounded border">
            <div class="d-flex align-items-center justify-content-between">
                <div>
                    {{ $t("Package") }}
                </div>

                <b-btn variant="link" size="sm" pill @click="changePackage">
                    {{ $t("Edit") }}
                </b-btn>
            </div>

            <h5 class="mb-0">
                <font-awesome-icon
                    icon="fa-duotone fa-coin"
                    class="text-warning"
                />
                {{ price.credits }}
                {{ $t("Credits") }}
            </h5>
            <div class="text-secondary">
                {{ price.label }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("wallet", ["price"]),
    },
    methods: {
        changePackage() {
            this.$store.commit("wallet/setPrice", null);
        },
    },
};
</script>