import api from "@/service/api";
import router from "@/router";

const state = () => ({
    withdrawalError: null,
    withdraw: null,
    currentWithdraw: null,
    types: null,
    successMessage: null,
    lastUserPayee: null,
    submitting: null,
    scheduledDeleteError: false,
})

const mutations = {
    reset(state) {
        state.currentWithdraw = null
        state.withdraw = null
        state.successMessage = null
        state.withdrawalError = null
        state.lastUserPayee = null
    },
    setError(state, value) {
        state.withdrawalError = value
    },
    setSuccessMessage(state, value) {
        state.successMessage = value
    },
    setWithdraw(state, value) {
        state.withdraw = value
    },
    setCurrentWithdraw(state, value) {
        state.currentWithdraw = value
    },
    setSubmitting(state, value) {
        state.submitting = value
    },
    setScheduledDeletedError(state, value) {
        state.scheduledDeleteError = value
    },
    setTypes(state, value) {
        state.types = value
    },
    setLastUserPayee(state, value) {
        state.lastUserPayee = value
    },
}

const trackConversionWithdrawal = (amount) => {
    try {
        gtag('event', 'conversion', {
            'send_to': 'AW-1044313876/CmhSCJ3ZtaAZEJTu-_ED',
            'value': amount,
            'currency': 'USD'
        });
    } catch (e) {
        // ignore
    }
}

/* global gtag */
const actions = {
    submit({ commit, state, dispatch }) {
        commit("setSubmitting", true);
        commit('setScheduledDeletedError', false)

        api
            .post("/withdraw/submit", {
                withdrawId: state.withdraw.id
            })
            .then(() => {
                trackConversionWithdrawal(state.withdraw.amountUsd)

                dispatch("onboarding/status", null, { root: true });
                commit("reset");
                router.push('/withdraw/index')
            })
            .catch((error) => {
                if (error?.response?.data?.code === 'scheduledDelete') {
                    commit('setScheduledDeletedError', true)
                }
            })
            .then(() => {
                commit("setSubmitting", false);
            })
    },
    cancel({ commit, state, dispatch }, { withoutRedirect } = {}) {
        if (!state.withdraw) {
            return;
        }

        api
            .post("/withdraw/cancel", {
                withdrawId: state.withdraw.id
            })
            .then(() => {
                dispatch("onboarding/status", null, { root: true });

                commit('reset')

                if (!withoutRedirect) {
                    router.push('/withdraw/index')
                }
            })
    },
    refresh({ commit }) {
        api
            .post("/withdraw/current")
            .then((response) => {
                commit("setWithdraw", response.data.withdraw);
            })
    },
    setWithdraw({ state, commit }, withdraw) {
        commit('setWithdraw', withdraw)

        if (!state.types) {
            api
                .post("/withdraw/types")
                .then((response) => {
                    if (response?.data?.types) {
                        commit("setTypes", response.data.types);
                    }
                })
        }
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
