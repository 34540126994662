<template>
    <div>
        <b-alert v-if="displayError" variant="warning" show>
            <font-awesome-icon
                icon="fa-duotone fa-triangle-exclamation"
                size="lg"
            />
            <span v-if="errorCode === 'paymentFailed'">
                {{ $t("Failed to charge your card") }}
            </span>
            <span v-if="errorCode === 'noPaymentMethod'">
                {{ $t("Not enough credit") }}
            </span>
            <span v-if="errorCode === 'notEnoughCredits'">
                {{ $t("Not enough credit") }}
            </span>
            <span v-if="errorCode === 'insufficientBalance'">
                {{ $t("Not enough credit") }}
            </span>
        </b-alert>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        displayError() {
            return this.errorCode && !this.method && !this.price;
        },
        ...mapState("wallet", ["errorCode", "method", "price"]),
    },
};
</script>