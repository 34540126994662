<template>
    <div>
        <div v-if="creatorAuthenticity">
            <authenticity-record />
        </div>
        <div v-else>
            <generic-loading />
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import AuthenticityRecord from "@/components/onboarding/authenticity/AuthenticityRecord";

export default {
    created() {
        this.$store.dispatch("creatorAuthenticity/refresh");
    },
    computed: {
        ...mapState("creatorAuthenticity", ["creatorAuthenticity"]),
    },
    components: {
        AuthenticityRecord,
    },
};
</script>
