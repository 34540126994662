<template>
    <div>
        <div v-if="withdraw.capitalistAccountNumber" class="my-3">
            <b-alert variant="dark" show>
                <h5 class="mb-0">
                    <font-awesome-icon
                        icon="fa-duotone fa-triangle-exclamation"
                    />
                    {{ $t("Confirm Account Number") }}
                </h5>

                <div class="mt-3">
                    <code class="lead">
                        {{ withdraw.capitalistAccountNumber }}
                    </code>
                </div>
                <div class="small mt-2">
                    {{
                        $t(
                            "If you need to make a correction, press cancel to start over"
                        )
                    }}
                </div>
                <div class="small mt-2 text-warning">
                    <font-awesome-icon
                        icon="fa-duotone fa-triangle-exclamation"
                    />
                    {{
                        $t(
                            "Card transfers cannot be reversed, if sent to the wrong address you will not get your money"
                        )
                    }}
                </div>
            </b-alert>

            <b-btn
                @click="submit"
                :disabled="submitting"
                variant="primary"
                size="lg"
                class="mt-3"
                pill
                block
            >
                {{ $t("Submit Withdrawal") }}
            </b-btn>
        </div>
        <div v-else>
            <div
                v-if="onboardingUser.capitalistAccountNumber"
                class="bg-light rounded p-3 mb-3"
            >
                <div>
                    {{ $t("Previous address") }}
                </div>
                <code class="">
                    {{ onboardingUser.capitalistAccountNumber }}
                </code>
                <div class="mt-3">
                    <b-btn
                        @click="usePreviousAddress"
                        variant="primary"
                        pill
                        block
                    >
                        {{ $t("Use this address") }}
                    </b-btn>
                </div>
            </div>

            <div class="mt-3">
                {{ $t("Bank Card Number") }}
            </div>

            <b-alert variant="danger" show v-if="error">
                {{ error }}
            </b-alert>

            <b-input-group>
                <b-form-input
                    v-model="capitalistAccountNumber"
                    tabindex="1"
                    maxlength="252"
                    class="border-light"
                    size="lg"
                    autocomplete="off"
                    :placeholder="$t('Card Number')"
                ></b-form-input>
                <b-input-group-append>
                    <b-btn @click="save" variant="primary" size="lg">
                        {{ $t("Save") }}
                    </b-btn>
                </b-input-group-append>
            </b-input-group>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            dismissedWiseSuggestion: false,
            capitalistAccountNumber: null,
            error: null,
        };
    },
    computed: {
        ...mapState("withdraw", ["withdraw", "lastUserPayee", "submitting"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        cancelWithdraw() {
            this.$store.dispatch("withdraw/cancel");
        },
        usePreviousAddress() {
            this.capitalistAccountNumber =
                this.onboardingUser.capitalistAccountNumber;
            this.save();
        },
        save() {
            this.api
                .post("/withdraw/capitalist", {
                    withdrawId: this.withdraw.id,
                    capitalistAccountNumber: this.capitalistAccountNumber,
                })
                .then((response) => {
                    if (response?.data?.withdraw) {
                        this.$store.dispatch(
                            "withdraw/setWithdraw",
                            response.data.withdraw
                        );
                    }
                })
                .catch((error) => {
                    if (error?.response?.data?.message) {
                        this.error = error?.response?.data?.message;
                    }
                });
        },
        submit() {
            this.$store.dispatch("withdraw/submit");
        },
    },
};
</script>