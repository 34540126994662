<template>
    <generic-page>
        <back-to-dashboard />

        <h3 class="mb-0">Discount</h3>

        <div class="mt-3">
            <b-table-simple striped class="mb-0 mt-3">
                <thead>
                    <th>Issue After</th>
                    <th>Discount</th>
                    <th>Status</th>
                    <th></th>
                </thead>
                <tbody>
                    <tr :key="discount.duration" v-for="discount in discounts">
                        <td class="align-middle">
                            {{ discount.duration }}
                        </td>
                        <td class="align-middle">{{ discount.percentage }}%</td>
                        <td class="align-middle">
                            <b-badge
                                v-if="discount.enabled"
                                pill
                                variant="primary"
                            >
                                {{ $t("Enabled") }}
                            </b-badge>
                            <b-badge v-else pill variant="dark">
                                {{ $t("Disabled") }}
                            </b-badge>
                        </td>
                        <td>
                            <b-dropdown variant="secondary" right size="sm">
                                <template #button-content>
                                    <font-awesome-icon
                                        icon="fa-duotone fa-cog"
                                    />
                                </template>
                                <b-dropdown-item
                                    v-if="discount.enabled"
                                    @click="disableDiscount(discount)"
                                >
                                    {{ $t("Disable") }}
                                </b-dropdown-item>
                                <b-dropdown-item
                                    v-else
                                    @click="enableDiscount(discount)"
                                >
                                    {{ $t("Enable") }}
                                </b-dropdown-item>
                            </b-dropdown>
                        </td>
                    </tr>
                </tbody>
            </b-table-simple>

            <b-alert variant="primary" show class="mt-3">
                <h5>
                    {{ $t("How it works") }}
                </h5>
                {{
                    $t(
                        "If enabled, introductory offers will automatically be sent to customers who have yet to purchase credits to re-engage with them, and entice them to pull the trigger"
                    )
                }}
                <div class="mt-3">
                    {{
                        $t(
                            "The discount will only be applied towards their first purchase, and will come out of your affiliate commission. These offers are optional but recommended as we have a high repeat purchase rate"
                        )
                    }}
                </div>
                <div class="mt-3">
                    {{
                        $t(
                            "Giving users a chance to evaluate the platform may induce them to continue to spend once the have evaluated the platform and begin interacting with users"
                        )
                    }}
                </div>
            </b-alert>
        </div>
    </generic-page>
</template>


<script>
import BackToDashboard from "@/components/utility/BackToDashboard";

export default {
    data() {
        return {
            discounts: null,
        };
    },
    mounted() {
        this.refresh();
    },
    methods: {
        enableDiscount(discount) {
            this.api
                .post("/affiliate/discount/enable", {
                    id: discount.id,
                })
                .then(() => {
                    this.refresh();
                });
        },
        disableDiscount(discount) {
            this.api
                .post("/affiliate/discount/disable", {
                    id: discount.id,
                })
                .then(() => {
                    this.refresh();
                });
        },
        refresh() {
            this.api.post("/affiliate/discount/index").then((response) => {
                this.discounts = response?.data?.discounts;
            });
        },
    },
    components: {
        BackToDashboard,
    },
};
</script>