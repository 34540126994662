<template>
    <div>
        <div class="py-3">
            <font-awesome-icon
                icon="fa-light fa-check-circle"
                size="5x"
                class="text-success"
            />
        </div>
        <div>
            Thank you for your application. We will review all information and
            approve your account for publishing to potential scouts and agencies
        </div>

        <div class="mt-4">
            Please indicate what kinds of modeling opportunities you are open
            to, we will try to match you with potential clients, and reach out
            to you once we have opportunities that match your preferences
        </div>

        <div class="bg-white p-3 text-left mt-3">
            <h5 class="text-center">Select Opportunities</h5>
            <div class="small">
                <font-awesome-icon
                    icon="fa-duotone fa-shield-check"
                    class="text-primary"
                />
                Your answers are confidential and will never be shown to clients
                or appear on your profile, this is only used to show you
                relevant work
            </div>
            <div :key="answer" v-for="answer in answers" class="mt-1">
                <b-btn
                    @click="toggleAnswer(answer)"
                    block
                    variant="light"
                    class="text-left"
                >
                    <font-awesome-icon
                        v-if="checked.includes(answer)"
                        icon="fa-light fa-check-circle"
                        class="text-success"
                    />
                    <font-awesome-icon v-else icon="fa-solid fa-circle" />
                    <span class="ml-2">{{ answer }}</span>
                </b-btn>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            checked: [],
            answers: [
                "General",
                "Fashion",
                "Swimsuit / Bikini",
                "Commercials",
                "Promotional",
                "Get Paid to Chat - Non Adult",
                "Get Paid to Chat - Adult",
                "Product / Service UGC",
                "Product / Service Influencer",
                "Brand Ambassador",
                "Virtual / Digital Model",
                "Adult Model",
                "Webcam Model",
            ],
        };
    },
    methods: {
        toggleAnswer(answer) {
            if (this.checked.includes(answer)) {
                this.checked = this.checked.filter((a) => a !== answer);

                this.api.post("/survey/remove", {
                    answer,
                });
            } else {
                this.checked.push(answer);

                this.api.post("/survey/add", {
                    answer,
                });
            }
        },
    },
};
</script>