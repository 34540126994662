import api from "@/service/api";

const state = () => ({
    inboundUserIds: [],
    outboundUserIds: [],
})

const mutations = {
    setInboundUserIds(state, value) {
        state.inboundUserIds = value;
    },
    setOutboundUserIds(state, value) {
        state.outboundUserIds = value;
    },
    addInboundUserId(state, userId) {
        state.inboundUserIds.push(userId)
    },
    removeInboundUserId(state, userId) {
        state.inboundUserIds = state.inboundUserIds.filter(id => id !== userId)
    },
    addOutboundUserId(state, userId) {
        state.outboundUserIds.push(userId)
    },
    removeOutboundUserId(state, userId) {
        state.outboundUserIds = state.outboundUserIds.filter(id => id !== userId)
    },
}

const actions = {
    add({ commit }, { userId }) {
        commit('addOutboundUserId', userId)
        api.post("/interest/add", { userId })
    },
    remove({ commit }, { userId }) {
        commit('removeOutboundUserId', userId)
        api.post("/interest/remove", { userId })
    },
    refresh({ commit }) {
        api.post("/interest/get")
            .then((response) => {
                commit('setInboundUserIds', response.data.inboundUserIds)
                commit('setOutboundUserIds', response.data.outboundUserIds)
            });
    },
}

const getters = {
    interestedInUserId: (state) => (userId) => {
        return state.outboundUserIds.includes(userId);
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
