<template>
    <div>
        <h5 class="text-primary mt-3">
            <font-awesome-icon
                class="text-warning"
                icon="fa-duotone fa-fire"
                size="lg"
            />
            High Quality Content Only
        </h5>
        <div>
            Your post should have high quality content, you should be looking
            your best and showcasing your best assets! If you upload a photo of
            your dog, your lunch or a sunset, it will not be accepted, every
            post should be
            <font-awesome-icon
                class="mr-1 text-warning"
                icon="fa-duotone fa-fire"
                size="lg"
            />
        </div>

        <h5 class="text-primary mt-3">{{ $t("Getting interests") }}</h5>
        <div>
            {{
                $t(
                    "When you post content, it shows in the feed for other users to view, if they enjoy your posts they will interest you meaning they will see more of your content"
                )
            }}
        </div>

        <h5 class="text-primary mt-3">{{ $t("Private Posts") }}</h5>
        <div>
            {{
                $t(
                    "Private posts cannot be viewed by a user until they pay to view it, once they pay they can view the post"
                )
            }}
        </div>

        <div class="text-center branded py-3">
            <font-awesome-icon icon="fa-light fa-arrow-down" size="2x" />
        </div>

        <b-btn @click="createPost" variant="primary" pill block size="lg">
            {{ $t("Create My First Post") }}
        </b-btn>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("creatorGuide", ["currentGuide", "guides"]),
        modal: {
            get() {
                return this.$store.state.creatorGuide.currentGuide
                    ? true
                    : false;
            },
            set(value) {
                if (!value) {
                    this.$store.commit("creatorGuide/reset");
                }
            },
        },
    },
    methods: {
        createPost() {
            this.$router.push({
                name: "creatorPost",
            });

            this.$store.commit("creatorGuide/reset");
        },
    },
    components: {},
};
</script>


