<template>
    <div>
        <b-modal
            :title="$t('Creator Request')"
            hide-footer
            v-model="modal"
            centered
            body-class="p-0"
            no-close-on-esc
            static
        >
            <user-header
                v-if="creatorStandardMap"
                :disableInterest="true"
                :standardMap="creatorStandardMap"
            />
            <creator-request-modal />
        </b-modal>
    </div>
</template>

<script>
import { mapState } from "vuex";

import CreatorRequestModal from "@/components/always/creatorRequest/CreatorRequestModal";
import UserHeader from "@/components/userHeader/UserHeader";

export default {
    computed: {
        modal: {
            get() {
                return this.$store.state.interface.creatorRequestModalVisible;
            },
            set(value) {
                this.$store.commit(
                    "interface/setCreatorRequestModalVisible",
                    value
                );
            },
        },
        ...mapState("creatorRequest", ["creatorStandardMap"]),
    },
    components: {
        CreatorRequestModal,
        UserHeader,
    },
};
</script>