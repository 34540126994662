const state = () => ({
    onboardingSidebarVisible: false,
    leftSidebarVisible: false,
    rightSidebarVisible: false,
    widgetPageAppSelectorVisible: false,
    profileFullSizeGalleryVisible: false,
    profilePrivateGalleryVisible: false,
    conversationActionsUploadPhotoTypeModalVisible: false,
    conversationActionMediaModalVisible: false,
    conversationTemplateModalVisible: false,
    conversationFullSizeGalleryVisible: false,
    reportUserModalVisible: false,
    settingEditingOnboardingStep: false,
    settingEditing: false,
    onboardingPhotoUploadMode: false,
    onboardingVideoUploadMode: false,
    onboardingSeekingOptionsVisible: false,
    webSubscriptionModalVisible: false,
    postMenuModalVisible: false,
    postPhotoModalVisible: false,
    displayOfferModalVisible: false,
    profileViewingContact: false,
    profileModalVisible: false,
    postHelpModalVisible: false,
    giphySelectorModalVisible: false,
    userWalletModalVisible: false,
    creatorSubscriptionModalVisible: false,
    creatorRequestModalVisible: false,
    creatorInviteModalVisible: false,
    creatorDashboardModalVisible: false,
    postCommentsModalVisible: false,
    sendCreditsModalVisible: false,
    editCreatorGalleryModalVisible: false,
    universalUploaderModalVisible: false,
    searchUserModalVisible: false,
    inviteFriendsModalVisible: false,
    influencerGuideModalVisible: false,
    creatorRulesModalVisible: false,
    creatorShareQrcodeModalVisible: false,
    messageInboxModalVisible: false,
    creatorInvitePageSettingsModalVisible: false,
    inviteEmailModalVisible: false,
    manageSubscriptionModalVisible: false,
    videoCallModalVisible: false,
    creatorLivestreamModalVisible: false,
    creatorLivestreamMinimized: false,
})

const mutations = {
    setCreatorLivestreamMinimized(state, value) {
        state.creatorLivestreamMinimized = value
    },
    setCreatorLivestreamModalVisible(state, value) {
        state.creatorLivestreamModalVisible = value
    },
    setVideoCallModalVisible(state, value) {
        state.videoCallModalVisible = value
    },
    setManageSubscriptionModalVisible(state, value) {
        state.manageSubscriptionModalVisible = value
    },
    setInviteEmailModalVisible(state, value) {
        state.inviteEmailModalVisible = value
    },
    setCreatorShareQrcodeModalVisible(state, value) {
        state.creatorShareQrcodeModalVisible = value
    },
    setCreatorInvitePageSettingsModalVisible(state, value) {
        state.creatorInvitePageSettingsModalVisible = value
    },
    setCreatorRulesModalVisible(state, value) {
        state.creatorRulesModalVisible = value
    },
    setInfluencerGuideModalVisible(state, value) {
        state.influencerGuideModalVisible = value
    },
    setInviteFriendsModalVisible(state, value) {
        state.inviteFriendsModalVisible = value
    },
    setSearchUserModalVisible(state, value) {
        state.searchUserModalVisible = value
    },
    setUniversalUploaderModalVisible(state, value) {
        state.universalUploaderModalVisible = value
    },
    setCreatorRequestModalVisible(state, value) {
        state.creatorRequestModalVisible = value
    },
    setEditCreatorGalleryModalVisible(state, value) {
        state.editCreatorGalleryModalVisible = value
    },
    setSendCreditsModalVisible(state, value) {
        state.sendCreditsModalVisible = value
    },
    setPostCommentsModalVisible(state, value) {
        state.postCommentsModalVisible = value
    },
    setCreatorDashboardModalVisible(state, value) {
        state.creatorDashboardModalVisible = value
    },
    setCreatorInviteModalVisible(state, value) {
        state.creatorInviteModalVisible = value
    },
    setCreatorSubscriptionModalVisible(state, value) {
        state.creatorSubscriptionModalVisible = value
    },
    setUserWalletModalVisible(state, value) {
        state.userWalletModalVisible = value
    },
    setGiphySelectorModalVisible(state, value) {
        state.giphySelectorModalVisible = value
    },
    setPostHelpModalVisible(state, value) {
        state.postHelpModalVisible = value
    },
    setProfileModalVisible(state, value) {
        state.profileModalVisible = value
    },
    setOnboardingPhotoUploadMode(state, value) {
        state.onboardingPhotoUploadMode = value
    },
    setOnboardingVideoUploadMode(state, value) {
        state.onboardingVideoUploadMode = value
    },
    setSettingEditing(state, value) {
        state.settingEditing = value
    },
    setSettingEditingOnboardingStep(state, value) {
        state.settingEditingOnboardingStep = value
    },
    setReportUserModalVisible(state, value) {
        state.reportUserModalVisible = value
    },
    setProfileFullSizeGalleryVisible(state, value) {
        state.profileFullSizeGalleryVisible = value
    },
    setProfilePrivateGalleryVisible(state, value) {
        state.profilePrivateGalleryVisible = value
    },
    setConversationFullSizeGalleryVisible(state, value) {
        state.conversationFullSizeGalleryVisible = value
    },
    setLeftSidebarVisible(state, value) {
        state.leftSidebarVisible = value
    },
    setRightSidebarVisible(state, value) {
        state.rightSidebarVisible = value
    },
    setOnboardingSidebarVisible(state, value) {
        state.onboardingSidebarVisible = value
    },
    setConversationActionsUploadPhotoTypeModalVisible(state, value) {
        state.conversationActionsUploadPhotoTypeModalVisible = value
    },
    setConversationActionMediaModalVisible(state, value) {
        state.conversationActionMediaModalVisible = value
    },
    setConversationTemplateModalVisible(state, value) {
        state.conversationTemplateModalVisible = value
    },
    setWebSubscriptionModalVisible(state, value) {
        state.webSubscriptionModalVisible = value
    },
    setWidgetPageAppSelectorVisible(state, value) {
        state.widgetPageAppSelectorVisible = value
    },
    setOnboardingSeekingOptionsVisible(state, value) {
        state.onboardingSeekingOptionsVisible = value
    },
    setPostMenuModalVisible(state, value) {
        state.postMenuModalVisible = value
    },
    setDisplayOfferModalVisible(state, value) {
        state.displayOfferModalVisible = value
    },
    setProfileViewingContact(state, value) {
        state.profileViewingContact = value
    },
    setPostPhotoModalVisible(state, value) {
        state.postPhotoModalVisible = value
    },
    setMessageInboxModalVisible(state, value) {
        state.messageInboxModalVisible = value
    },
}

const actions = {

}

const getters = {
    isSettingsMode(state, getters, rootState) {
        if (rootState.setting.editOnboardingStep) {
            return true
        }

        return false;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
