<template>
    <div>
        <img
            height="200"
            width="200"
            class="rounded-circle"
            :src="brand.homepageLogo"
        />

        <h1 class="mt-2">
            {{ brand.name }}
        </h1>

        <div v-if="brand.showPoweredBy" class="mt-3 text-secondary small">
            Powered by Findmate
        </div>
    </div>
</template>



<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("brand", ["brand"]),
    },
};
</script>
        