import api from "@/service/api";

import * as Sentry from "@sentry/vue";

const state = () => ({
    steps: null,
    status: null,
    hasIncompleteSteps: true,
    currentStep: null,
    user: null,
    trackedConversion: false,
})

const mutations = {
    setSteps(state, steps) {
        state.steps = steps;

        // only move on to next step when 
        // there are incomplete steps
        const nextIncompleteStep = steps.find(step => !step.complete)

        if (nextIncompleteStep) {
            state.currentStep = nextIncompleteStep
            state.hasIncompleteSteps = true
        } else {
            state.hasIncompleteSteps = false
        }
    },
    removeCurrentStep(state) {
        state.currentStep = null;
    },
    setCurrentStep(state, step) {
        const currentStepPosition = state.steps.findIndex(step => !step.complete)
        const destinationStepPosition = state.steps.findIndex(searchStep => searchStep.slug === step.slug)

        // prevent client from jumping to a step that isn't completed yet.
        // critical to prevent the video step from revealing whether its skippable
        if (state.hasIncompleteSteps && (destinationStepPosition > currentStepPosition)) {
            return;
        }

        state.currentStep = step;
    },
    removeUser(state) {
        state.user = null;
    },
    setUser(state, user) {
        state.user = user;

        if (user?.id) {
            Sentry.setUser({ id: user.id });
        }
    },
    hasIncompleteSteps(state, value) {
        state.hasIncompleteSteps = value;
    },
    setConversionTracked(state, value) {
        state.trackedConversion = value;
    },
}

/* global gtag */
const actions = {
    checkIfUserIsReadyToInitialize({ state, dispatch }) {
        if (state.hasIncompleteSteps) {
            return;
        }

        dispatch('user/initialize', null, { root: true })
    },
    trackConversion({ state, commit }) {
        if (state.conversionTracked) {
            return;
        }

        try {
            gtag('event', 'conversion', { 'send_to': 'AW-1044313876/suj_CMXS44UYEJTu-_ED' });
        } catch (e) {
            // @TODO
            // report to sentry
        }

        commit('setConversionTracked', true)
        api.post('/onboarding/setConverstionTracked')
    },
    resetFlags({ commit, dispatch }) {
        api.post("/onboarding/resetFlags").then(() => {
            commit("interface/setDisplayOfferModalVisible", false, { root: true });
            dispatch("onboarding/status", null, { root: true });
        });
    },
    status({ commit, dispatch, rootState }) {
        return new Promise((resolve, reject) => {
            api.post('/onboarding/status').then(response => {
                if (response?.data?.user) {
                    commit('setUser', response.data.user)
                }

                // server side upgrade during onboarding process
                if (response?.data?.user?.premium && !rootState.user.premium) {
                    commit('user/enablePremium', null, { root: true })
                }

                // signal to client to fetch offer
                if (response?.data?.user?.hasCurrentOffer) {
                    dispatch('displayOffer/getCurrent', null, { root: true })
                }

                // signal to track conversions
                if (response?.data?.user?.hasPendingConversionToTrack) {
                    dispatch('trackConversion')
                }

                if (response?.data?.steps) {
                    commit('setSteps', response.data.steps)
                    dispatch('checkIfUserIsReadyToInitialize')
                }

                // dynamically load creator or affiliate portal settings
                if (response?.data?.user?.affiliate) {
                    dispatch('affiliate/refresh', null, { root: true })
                }
                // todo, remove duplicate creator dashboard refresh calls, centralize here so its always loaded
            }).catch((error) => {
                console.error(error)
                reject()
            }).then(() => {
                resolve()
            })
        })
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
