<template>
    <generic-page>
        <div v-if="customer">
            <b-btn
                variant="link"
                size="lg"
                class="pl-0"
                to="/affiliate/customer/index"
            >
                <font-awesome-icon size="lg" icon="fa-light fa-chevron-left" />
                Customers
            </b-btn>

            <div>
                <div
                    class="d-flex align-items-center justify-content-between my-2"
                >
                    <div class="d-flex align-items-center">
                        <img
                            class="rounded mr-2"
                            :src="customer.standardMap.photo"
                        />
                        <h5 class="mb-0">
                            {{ customer.standardMap.name }}
                        </h5>
                    </div>
                    <div>
                        <coin-badge
                            class="bg-white"
                            :amount="customer.balance"
                            label="Current Balance"
                        />
                    </div>
                </div>

                <b-list-group>
                    <b-list-group-item
                        class="d-flex align-items-center justify-content-between"
                    >
                        Last Active

                        <div class="d-flex align-items-center">
                            {{ customer.standardMap.lastActiveRelative }}
                            <b-badge
                                variant="dark"
                                v-if="customer.stealthMode"
                                class="ml-2"
                                v-b-tooltip.click
                                title="User has stealth mode enabled, last active time will not be updated even if they use the platform"
                            >
                                <font-awesome-icon
                                    icon="fa-duotone fa-user-secret"
                                />
                                Stealth
                            </b-badge>
                        </div>
                    </b-list-group-item>
                    <b-list-group-item
                        class="d-flex align-items-center justify-content-between"
                    >
                        Created
                        <div class="d-flex align-items-center">
                            {{ customer.standardMap.createdRelative }}
                        </div>
                    </b-list-group-item>
                    <b-list-group-item
                        class="d-flex align-items-center justify-content-between"
                    >
                        Location
                        <div class="d-flex align-items-center">
                            <img
                                class="mr-1"
                                :src="customer.standardMap.flagUrl"
                            />
                            {{ customer.standardMap.country }}
                        </div>
                    </b-list-group-item>
                    <b-list-group-item
                        class="d-flex align-items-center justify-content-between"
                        v-if="customer.creditRefillTotal"
                    >
                        Total Spend
                        <div>${{ customer.creditRefillTotal }}</div>
                    </b-list-group-item>
                    <b-list-group-item
                        class="d-flex align-items-center justify-content-between"
                        v-if="customer.source"
                    >
                        Source
                        <div>
                            {{ customer.source }}
                        </div>
                    </b-list-group-item>
                    <b-list-group-item
                        class="d-flex align-items-center justify-content-between"
                        v-if="customer.landing"
                    >
                        Landing Page
                        <div>
                            {{ customer.landing }}
                        </div>
                    </b-list-group-item>
                    <b-list-group-item
                        class="d-flex align-items-center justify-content-between"
                        v-if="customer.daysActive"
                    >
                        Days Active
                        <div>
                            {{ customer.daysActive }}
                        </div>
                    </b-list-group-item>
                    <b-list-group-item
                        class="d-flex align-items-center justify-content-between"
                        v-if="customer.activityCount"
                    >
                        Activity Count
                        <div>
                            {{ customer.activityCount }}
                        </div>
                    </b-list-group-item>
                </b-list-group>

                <h5 class="mt-3">Commission History</h5>
                <b-table-simple striped class="mb-0 mt-3">
                    <thead>
                        <th>Date</th>
                        <th>Commission</th>
                    </thead>
                    <tbody>
                        <tr
                            :key="inviteReward.id"
                            v-for="inviteReward in customer.inviteRewards"
                        >
                            <td>
                                {{ inviteReward.createdRelative }}
                            </td>
                            <td>{{ inviteReward.commissionLocal }}</td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold bg-white">Total</td>
                            <td class="font-weight-bold bg-white">
                                {{ customer.totalRewards }}
                            </td>
                        </tr>
                    </tbody>
                </b-table-simple>
            </div>
        </div>
        <div v-else>
            <generic-loading />
        </div>
    </generic-page>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            customer: null,
        };
    },
    created() {
        if (this.$route.params?.id) {
            this.refresh();
        }
    },
    computed: {
        ...mapState("affiliate"),
    },
    methods: {
        refresh() {
            this.api
                .post("/affiliate/customer/get", {
                    userId: this.$route.params?.id,
                })
                .then((response) => {
                    if (response?.data?.customer) {
                        this.customer = response.data.customer;
                    }
                });
        },
    },
    components: {},
};
</script>