<template>
    <generic-page>
        <back-to-dashboard />

        <div class="d-flex align-items-center justify-content-between">
            <h5>
                {{ $t("My Money") }}
            </h5>
        </div>

        <div v-if="withdraw">
            <withdraw-pending />
        </div>

        <earnings-stats />

        <earnings-navigation class="mt-3" />
    </generic-page>
</template>

<script>
import { mapState } from "vuex";

import EarningsNavigation from "@/components/earnings/EarningsNavigation";
import EarningsStats from "@/components/earnings/EarningsStats";
import WithdrawPending from "@/components/withdraw/WithdrawPending";
import BackToDashboard from "@/components/utility/BackToDashboard";

export default {
    mounted() {
        this.$store.dispatch("withdraw/refresh");
    },
    computed: {
        ...mapState("withdraw", ["withdraw"]),
    },
    components: {
        EarningsNavigation,
        EarningsStats,
        WithdrawPending,
        BackToDashboard,
    },
};
</script>