<template>
    <b-btn
        to="/creator/video/index"
        class="bg-white"
        variant="light"
        block
        size="lg"
    >
        <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
                <div class="mr-2">
                    <font-awesome-icon
                        class="small-avatar branded"
                        icon="fa-duotone fa-camcorder"
                        size="lg"
                    />
                </div>
                {{ $t("Videos") }}
            </div>
            <div v-if="showWarning" class="text-warning">
                <font-awesome-icon icon="fa-duotone fa-triangle-exclamation" />
                {{ $t("No recent videos") }}
            </div>
        </div>
    </b-btn>
</template>


<script>
import { mapState } from "vuex";

export default {
    computed: {
        showWarning() {
            return this.dashboard && !this.dashboard.hasRecentVideo;
        },
        ...mapState("creatorDashboard", ["dashboard"]),
    },
};
</script>