<template>
    <generic-page>
        <b-btn
            variant="link"
            size="lg"
            class="pl-0"
            :to="`/creator/contentOffer/view/${contentOffer.id}`"
        >
            <font-awesome-icon size="lg" icon="fa-light fa-chevron-left" />
            {{ $t("Back to Offer") }}
        </b-btn>

        <div v-if="contentSubmission">
            <div class="d-flex align-items-center justify-content-between">
                <h5>
                    {{ contentOffer.title }}
                </h5>
                <coin-badge label="Reward" :amount="contentOffer.reward" />
            </div>
            <div>
                {{ contentOffer.description }}
            </div>

            Viewing Submission {{ contentSubmission.id }}

            <video-player
                :loop="false"
                :muted="false"
                :controls="true"
                :autoplay="false"
                :mp4Url="contentSubmission.mp4Url"
            />
        </div>
        <div v-else>
            <generic-loading />
        </div>
    </generic-page>
</template>

<script>
import VideoPlayer from "@/components/utility/VideoPlayer";

export default {
    data() {
        return {
            contentSubmission: null,
            contentOffer: null,
        };
    },
    created() {
        this.refresh();
    },
    watch: {
        $route(to) {
            if (to?.params?.id !== this.contentSubmission?.id) {
                this.refresh();
            }
        },
    },
    methods: {
        refresh() {
            this.api
                .post(`/contentSubmission/${this.$route.params.id}/get`)
                .then((response) => {
                    if (response?.data?.contentSubmission) {
                        this.contentSubmission =
                            response.data.contentSubmission;
                        this.contentOffer = response.data.contentOffer;
                    }
                });
        },
    },
    components: {
        VideoPlayer,
    },
};
</script>