<template>
    <div>
        <h5 class="mb-0">
            {{ $t("Gallery to Share") }}
        </h5>
        <div>
            {{ creatorGallery.name }}
        </div>

        <div class="mt-3 d-flex align-items-center justify-content-between">
            <h5>
                {{ $t("Interested in me") }}
            </h5>
            <div>
                <wallet-balance />
            </div>
        </div>

        <div v-if="interests">
            <div :key="interest.defaultMap.id" v-for="interest in interests">
                <user-standard-avatar
                    :timestamp="interest.defaultMap.created"
                    :standardMap="interest.standardMap"
                    :nonPrimaryPhotos="false"
                    :hideBadges="true"
                    :userBetweenStatus="false"
                    size="small"
                    class="border-light border-top"
                >
                    <div v-if="hasInvitedUserId(interest.standardMap.id)">
                        <font-awesome-icon
                            class="text-primary"
                            icon="fa-light fa-check"
                        />
                        {{ $t("Shared") }}
                    </div>
                    <div v-else>
                        <b-btn
                            variant="primary"
                            pill
                            size="sm"
                            class="mt-1"
                            @click="sendInvite(interest.standardMap.id)"
                        >
                            {{ $t("Share") }}
                            <coin-badge :amount="1" />
                        </b-btn>
                    </div>
                </user-standard-avatar>
            </div>
        </div>
        <div v-else>
            <generic-loading />
        </div>

        <div
            v-if="showPagination"
            class="d-flex align-items-center justify-content-between p-3"
        >
            <div>
                <b-btn
                    @click="previous"
                    variant="light"
                    size="lg"
                    block
                    pill
                    class="p-3 border-0 d-flex align-items-center"
                    :disabled="page === 1"
                >
                    <font-awesome-icon icon="fa-light fa-arrow-left" />
                    <div class="ml-2 d-none d-md-block">
                        {{ $t("Previous") }}
                    </div>
                </b-btn>
            </div>
            <div class="text-center text-secondary">Page {{ page }}</div>
            <div>
                <b-btn
                    @click="next"
                    variant="light"
                    size="lg"
                    block
                    pill
                    class="p-3 border-0 d-flex align-items-center"
                    :disabled="!hasMorePages"
                >
                    <div class="mr-2 d-none d-md-block">
                        {{ $t("Next") }}
                    </div>
                    <font-awesome-icon icon="fa-light fa-arrow-right" />
                </b-btn>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import UserStandardAvatar from "@/components/user/UserStandardAvatar";
import WalletBalance from "@/components/utility/WalletBalance";

export default {
    data() {
        return {
            loadingInterests: false,
            interests: false,
            inviteUserIds: null,
            hasMorePages: false,
            page: 1,
        };
    },
    created() {
        this.refreshShares();
        this.refreshFollowers();
    },
    computed: {
        showPagination() {
            if (!this.hasMorePages && this.page === 1) {
                return false;
            }

            return true;
        },
        ...mapState("creatorGallery", ["creatorGallery"]),
        ...mapState("creatorGuide", ["refillCommissionPercentage"]),
    },
    methods: {
        hasInvitedUserId(userId) {
            return this.inviteUserIds?.includes(userId);
        },
        refreshFollowers() {
            //needs updating
            // this.interests = null;
            // this.loadingInterests = true;
            // this.api
            //     .post("/interest/inbound", {
            //         page: this.page,
            //     })
            //     .then((response) => {
            //         if (response?.data?.interests) {
            //             this.interests = response.data.interests;
            //         }
            //         this.hasMorePages = response.data.hasMorePages;
            //     })
            //     .catch(() => {})
            //     .then(() => {
            //         this.loadingInterests = true;
            //     });
        },
        refreshShares() {
            this.api
                .post("/creatorGalleryInvite/getInvitedUserIds", {
                    creatorGalleryId: this.creatorGallery.id,
                })
                .then((response) => {
                    if (response?.data?.inviteUserIds) {
                        this.inviteUserIds = response.data.inviteUserIds;
                    }
                });
        },
        sendInvite(userId) {
            this.api
                .post("/creatorGalleryInvite/create", {
                    creatorGalleryId: this.creatorGallery.id,
                    userId,
                })
                .then(() => {
                    this.refreshShares();
                    this.$store.dispatch("onboarding/status");
                });
        },
        next() {
            this.page++;
            this.$scrollToTop();
            this.refreshFollowers();
        },
        previous() {
            this.page--;
            this.$scrollToTop();
            this.refreshFollowers();
        },
    },
    components: {
        UserStandardAvatar,
        WalletBalance,
    },
};
</script>