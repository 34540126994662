<template>
    <div class="p-3">
        <div v-if="sentRequest" class="text-center py-3">
            <creator-request-sent />
        </div>
        <div v-else-if="!onboardingUser.guideHowRequestWorksCompleted">
            <creator-request-explainer />
        </div>
        <div v-else>
            <creator-request-create />
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import CreatorRequestExplainer from "@/components/always/creatorRequest/CreatorRequestExplainer";
import CreatorRequestSent from "@/components/always/creatorRequest/CreatorRequestSent";
import CreatorRequestCreate from "@/components/always/creatorRequest/CreatorRequestCreate";

export default {
    computed: {
        ...mapState("creatorRequest", ["sentRequest"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    components: {
        CreatorRequestExplainer,
        CreatorRequestSent,
        CreatorRequestCreate,
    },
};
</script>
