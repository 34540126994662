<template>
    <div v-if="onboardingUser.allowedToSkipVideo" @click="skipVideoConfirm">
        <slot />
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        skipVideo() {
            this.api
                .post("/onboarding/skipVideo")
                .then(() => {})
                .catch(() => {})
                .then(() => {
                    this.$store.dispatch("onboarding/status");
                });
        },
        skipVideoConfirm() {
            if (this.onboardingUser.hasVerificationVideo) {
                this.skipVideo();
            } else {
                if (
                    confirm(
                        this.$t(
                            "Are you sure? Your profile may not be accepted"
                        )
                    )
                ) {
                    this.skipVideo();
                }
            }
        },
    },
};
</script>