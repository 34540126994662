<template>
    <div>
        <h5 class="mb-3 text-center">
            {{ $t("Choose Refill Package") }}
        </h5>
        <table class="table table-lg table-striped border mt-2">
            <thead>
                <th>
                    {{ $t("Package") }}
                </th>
                <th class="text-center align-middle">
                    {{ $t("Checkout") }}
                </th>
            </thead>
            <tbody>
                <tr :key="price.id" v-for="price in prices">
                    <td class="text-left">
                        <div>
                            <h5 class="mb-0">
                                <font-awesome-icon
                                    icon="fa-duotone fa-coin"
                                    class="text-warning"
                                />
                                {{ price.credits }}
                                Credits
                            </h5>
                            <div class="text-secondary">
                                {{ price.label }}
                            </div>
                        </div>
                    </td>
                    <td class="text-center align-middle">
                        <b-btn
                            @click="selectPackage(price)"
                            pill
                            block
                            variant="primary"
                        >
                            {{ $t("Buy") }}
                            ${{ price.amount }}
                            <div class="small text-opaque">
                                {{ price.localPrice }}
                            </div>
                        </b-btn>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    methods: {
        selectPackage(price) {
            this.$store.commit("wallet/setPrice", price);
        },
    },
    props: ["prices"],
};
</script>