<template>
    <div class="p-3">
        <div
            class="bg-light p-3 d-flex align-items-center justify-content-between rounded"
        >
            <div>Stealth Mode</div>
            <b-form-checkbox v-model="stealthMode" switch size="lg">
            </b-form-checkbox>
        </div>
        <div class="py-2">
            <font-awesome-icon icon="fa-light fa-check" class="text-success" />
            You will no longer appear online with a green dot to other users,
            however, you will still be able to see when other users are online
        </div>
        <div class="py-2">
            <font-awesome-icon icon="fa-light fa-check" class="text-success" />
            Your profile will still be visible, but we won't update your last
            active time, so it will appear to others that you have become
            inactive, and have stopped logging in, even while you continue to
            use the app
        </div>
        <div class="py-2">
            <font-awesome-icon icon="fa-light fa-check" class="text-success" />
            After turning stealth mode off or on, you should refresh so it takes
            full effect
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        stealthMode: {
            get() {
                return this.onboardingUser.stealthMode;
            },
            set(enable) {
                this.updateStealthMode(enable);
            },
        },

        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        updateStealthMode(enable) {
            this.api.post("/userFlag/stealthMode", { enable }).then(() => {
                this.$store.dispatch("onboarding/status");
            });
        },
    },
};
</script>