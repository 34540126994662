<template>
    <div>
        <b-alert variant="danger" show v-if="error">
            {{ error }}
        </b-alert>

        <div class="d-flex justify-content-between align-items-center">
            <div>
                {{ $t("Saved Card") }}
            </div>
            <div>
                <b-btn
                    @click="createCard"
                    variant="primary"
                    pill
                    class="mt-3"
                    size="sm"
                >
                    {{ $t("New Card") }}
                </b-btn>
            </div>
        </div>

        <div>
            <font-awesome-icon
                icon="fa-duotone fa-credit-card"
                class="text-primary"
                size="lg"
            />
            {{ $t("Card ending in ") }}
            {{ onboardingUser.primaryCreditCard.lastFour }}
        </div>

        <refill-brand-warning />

        <b-btn
            :disabled="saving"
            @click="chargeCard"
            size="lg"
            variant="primary"
            pill
            block
            class="mt-3"
        >
            {{ $t("Pay") }}
            ${{ price.amount }}
            <div class="small text-opaque">
                {{ price.localPrice }}
            </div>
        </b-btn>
    </div>
</template>

<script>
import { mapState } from "vuex";

import RefillBrandWarning from "@/components/wallet/refill/RefillBrandWarning";

export default {
    computed: {
        ...mapState("wallet", ["price"]),
        ...mapState("authorize", ["saving", "error"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        createCard() {
            this.$store.commit("wallet/setCreateCard", true);
        },
        changePackage() {
            this.$store.commit("wallet/setPrice", null);
        },
        chargeCard() {
            this.$store.dispatch("authorize/chargeCard", {
                priceId: this.price.id,
                userCreditCardId: this.onboardingUser.primaryCreditCard.id,
            });
        },
    },
    components: {
        RefillBrandWarning,
    },
};
</script>