<template>
    <div>
        <b-skeleton-wrapper :loading="showSkeleton">
            <template #loading>
                <div :key="n" v-for="n in Array(3)">
                    <div class="mb-4">
                        <div class="bg-white border">
                            <div class="d-flex align-items-center p-2">
                                <div class="mr-2">
                                    <b-skeleton-img
                                        class="rounded border border-light"
                                        height="50px"
                                        width="50px"
                                    ></b-skeleton-img>
                                </div>
                                <div class="flex-grow-1">
                                    <b-skeleton width="15%"></b-skeleton>
                                    <b-skeleton
                                        class="mt-1"
                                        width="35%"
                                    ></b-skeleton>
                                </div>
                                <div>
                                    <b-btn
                                        variant="light"
                                        pill
                                        class="d-flex align-items-center btn-brand"
                                    >
                                        <font-awesome-icon
                                            size="sm"
                                            icon="fa-light fa-plus"
                                            class="text-secondary mr-1"
                                        />
                                        {{ $t("Interested") }}
                                    </b-btn>
                                </div>
                            </div>
                        </div>
                        <div>
                            <b-skeleton-img
                                height="500px"
                                width="500px"
                                class="img-fluid"
                            ></b-skeleton-img>
                        </div>
                        <div class="story bg-white p-3">
                            <b-skeleton class="mt-2" width="95%"></b-skeleton>
                            <b-skeleton class="mt-2" width="75%"></b-skeleton>
                            <b-skeleton class="mt-2" width="85%"></b-skeleton>
                        </div>
                    </div>
                </div>
            </template>

            <slot />
        </b-skeleton-wrapper>
    </div>
</template>


<script>
import { mapState } from "vuex";

export default {
    computed: {
        showSkeleton() {
            return !this.creatorPosts && this.postIndexLoading;
        },
        ...mapState("postIndex", ["postIndexLoading", "creatorPosts"]),
    },
};
</script>