<template>
    <div>
        <b-row class="bg-light" no-gutters>
            <b-col>
                <profile-action-chat />
            </b-col>
            <b-col v-if="!isRestricted && canCallUser">
                <profile-action-call />
            </b-col>
            <b-col>
                <profile-action-interest />
            </b-col>
            <b-col v-if="showUncensoredCreatorActions">
                <profile-action-send />
            </b-col>
            <b-col v-if="showUncensoredCreatorActions && brand.enableRequest">
                <profile-action-request />
            </b-col>
            <b-col v-if="false && onboardingUser.creator">
                <profile-action-invite />
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import ProfileActionChat from "@/components/profile/action/ProfileActionChat";
import ProfileActionCall from "@/components/profile/action/ProfileActionCall";
import ProfileActionSend from "@/components/profile/action/ProfileActionSend";
import ProfileActionRequest from "@/components/profile/action/ProfileActionRequest";
import ProfileActionInterest from "@/components/profile/action/ProfileActionInterest";
import ProfileActionInvite from "@/components/profile/action/ProfileActionInvite";

export default {
    computed: {
        ...mapGetters("creator", ["isRestricted"]),
        ...mapGetters("profile", [
            "showUncensoredCreatorActions",
            "canCallUser",
        ]),
        ...mapState("brand", ["brand"]),
        ...mapState("profile", ["profile"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    components: {
        ProfileActionSend,
        ProfileActionRequest,
        ProfileActionInvite,
        ProfileActionChat,
        ProfileActionCall,
        ProfileActionInterest,
    },
};
</script>