<template>
    <div>
        <div v-if="recording">
            <div class="d-flex align-items-center justify-content-between">
                <div class="text-danger">
                    <b-spinner small type="grow" variant="danger" />
                    <strong> Recording </strong>
                </div>

                <second-timer class="text-danger" v-if="timer" />
            </div>

            <video
                ref="video"
                class="img-fluid"
                playsinline
                autoplay
                muted
            ></video>

            <b-btn
                @click="stopRecording"
                pill
                variant="primary"
                size="lg"
                block
                class="mt-3"
            >
                Finished Recording
            </b-btn>
        </div>
        <div v-else-if="recordingUploading">
            <generic-loading label="Uploading" />
        </div>
        <div v-else>
            <b-alert v-if="exceededDurationLimit" variant="danger" show>
                The submitted video is longer than the limit of
                {{ config.systemRuleProfileVideoMaxDuration }} seconds
            </b-alert>
            <b-btn @click="startRecording" pill variant="primary" size="lg">
                <font-awesome-icon icon="fa-duotone fa-camcorder" />
                Record Video
            </b-btn>
        </div>
    </div>
</template>

<script>
import SecondTimer from "@/components/utility/SecondTimer";
import { mapState } from "vuex";

export default {
    data() {
        return {
            exceededDurationLimit: false,
            timer: false,
            stream: null,
            mediaRecorder: null,
            recordedChunks: [],
        };
    },
    beforeDestroy() {
        if (this.stream) {
            this.stream.getTracks().forEach((track) => track.stop());
        }
    },
    computed: {
        ...mapState("options", ["config"]),
        ...mapState("contentOffer", [
            "contentOffer",
            "recording",
            "recordingUploading",
        ]),
    },
    methods: {
        async startRecording() {
            this.$store.commit("contentOffer/setRecording", true);

            this.stream = await navigator.mediaDevices.getUserMedia({
                video: true,
                audio: true,
            });

            this.timer = true;

            this.$refs.video.srcObject = this.stream;

            // danger, removed mime type, make sure flow is same
            const options = {
                // mimeType: "video/mp4",
            };

            this.mediaRecorder = new MediaRecorder(this.stream, options);

            this.mediaRecorder.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    this.recordedChunks.push(event.data);
                }
            };
            this.mediaRecorder.start();
        },
        stopRecording() {
            this.mediaRecorder.stop();

            this.mediaRecorder.onstop = () => {
                const blob = new Blob(this.recordedChunks, {
                    type: "video/mp4",
                });

                this.uploadCloudinary(blob);
            };

            // Stop all tracks to end the stream
            this.stream.getTracks().forEach((track) => track.stop());

            this.$store.commit("contentOffer/setRecording", false);
        },
        uploadCloudinary(blob) {
            this.$store.commit("contentOffer/setRecordingUploading", true);

            const formData = new FormData();

            formData.append("file", blob);
            formData.append("upload_preset", "contentOffer");

            this.exceededDurationLimit = false;

            fetch(`https://api.cloudinary.com/v1_1/findmateapp/video/upload`, {
                method: "POST",
                body: formData,
            })
                .then((response) => response.json())
                .then((data) => {
                    if (
                        data.duration >
                        this.config.systemRuleProfileVideoMaxDuration
                    ) {
                        this.$store.commit(
                            "contentOffer/setRecordingUploading",
                            false
                        );
                        this.exceededDurationLimit = true;
                        return;
                    }

                    this.$store.commit("contentOffer/setUploading", true);

                    this.api
                        .post(
                            `/contentOffer/${this.contentOffer.id}/uploadCloudinary`,
                            {
                                data,
                            }
                        )
                        .then(() => {
                            this.$store.dispatch("contentOffer/refresh");
                        })
                        .catch((error) => {
                            this.error = error.response.data.message;
                            this.uploadingUrl = null;
                        })
                        .then(() => {
                            this.$store.commit(
                                "contentOffer/setUploading",
                                false
                            );

                            this.$store.commit(
                                "contentOffer/setRecordingUploading",
                                false
                            );
                        });
                })
                .catch((error) => console.error(error));
        },
    },
    components: {
        SecondTimer,
    },
    props: ["code"],
};
</script>

<style scoped>
#upload_widget_container {
    position: relative;
}
</style>
