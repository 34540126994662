<template>
    <b-alert v-if="!brand.isFindmate" variant="primary" class="mt-2" show>
        <font-awesome-icon icon="fa-duotone fa-info-circle" />
        {{ $t("Charges on your card will show as:") }}
        <span class="font-weight-bold text-success">FINDMATE, LLC</span>
    </b-alert>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("brand", ["brand"]),
    },
};
</script>