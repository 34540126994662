<template>
    <div>
        <account-section-title
            class="d-flex align-items-center justify-content-between"
        >
            Activity
            <wallet-balance />
        </account-section-title>

        <b-list-group flush>
            <b-list-group-item
                v-if="purchasedGalleryCount"
                @click="viewPurchasedGalleries"
                button
                class="setting-item"
            >
                <div class="label">Gallery</div>
                <div class="value">
                    {{ purchasedGalleryCount }}
                </div>
            </b-list-group-item>

            <b-list-group-item
                v-if="creatorRequestCount"
                to="/account/requests"
                class="setting-item"
            >
                <div class="label">Requests</div>
                <div class="value">
                    <b-badge
                        variant="primary"
                        pill
                        v-if="unseenCreatorRequestCount"
                    >
                        {{ unseenCreatorRequestCount }}
                        new
                    </b-badge>
                    <span v-else>
                        {{ creatorRequestCount }}
                    </span>
                </div>
            </b-list-group-item>
            <b-list-group-item
                v-if="callCount"
                to="/account/call/index"
                class="setting-item"
            >
                <div class="label">Calls</div>
                <div class="value">
                    {{ callCount }}
                </div>
            </b-list-group-item>
            <b-list-group-item
                v-if="creatorVideoFavoriteCount"
                to="/account/video/favorite"
                class="setting-item"
            >
                <div class="label">Favorite Video</div>
                <div class="value">
                    {{ creatorVideoFavoriteCount }}
                </div>
            </b-list-group-item>
            <b-list-group-item
                v-if="inboundUserIds.length"
                to="/account/interest/inbound"
                class="setting-item"
            >
                <div class="label">
                    {{ $t("Interests Received") }}
                </div>
                <div class="value">
                    {{ inboundUserIds.length }}
                </div>
            </b-list-group-item>
            <b-list-group-item
                v-if="outboundUserIds.length"
                to="/account/interest/outbound"
                class="setting-item"
            >
                <div class="label">
                    {{ $t("Interests Sent") }}
                </div>
                <div class="value">
                    {{ outboundUserIds.length }}
                </div>
            </b-list-group-item>
            <b-list-group-item
                v-if="viewedProfileCount"
                to="/account/userVisit/index"
                class="setting-item"
            >
                <div class="label">Viewed Profiles</div>
                <div class="value">
                    {{ viewedProfileCount }}
                </div>
            </b-list-group-item>
        </b-list-group>
    </div>
</template>

<script>
import { mapState } from "vuex";

import AccountSectionTitle from "@/components/account/AccountSectionTitle";
import WalletBalance from "@/components/utility/WalletBalance";

export default {
    created() {
        this.$store.dispatch("account/refresh");

        // interest reflection removed
        // not critical like msg, if re-enabled this can be removed
        this.$store.dispatch("interest/refresh");
    },
    computed: {
        ...mapState("interest", ["inboundUserIds", "outboundUserIds"]),
        ...mapState("account", [
            "creatorRequestCount",
            "unseenCreatorRequestCount",
            "purchasedGalleryCount",
            "callCount",
            "creatorVideoFavoriteCount",
            "viewedProfileCount",
        ]),
        ...mapState("system", ["cordova"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        viewPurchasedGalleries() {
            this.$router.push({
                name: "galleryIndex",
            });

            this.$store.dispatch("galleryIndex/setView", "purchased");
        },
    },
    components: {
        AccountSectionTitle,
        WalletBalance,
    },
};
</script>