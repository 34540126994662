<template>
    <div class="text-left">
        <b-alert variant="success" show class="text-center">
            <h3>
                {{ $t("Entry Fee") }}
            </h3>

            <div class="mt-2">
                {{
                    $t(
                        "To restore your account, you must pay an entry fee of $25"
                    )
                }}
            </div>
            <div class="mt-2">
                {{ $t("Once paid, your account will be instantly restored") }}
            </div>
        </b-alert>

        <div class="p-3">
            <b-button
                v-b-toggle.creatorEntryFeeHowItWorks
                variant="secondary"
                block
                size="sm"
                pill
            >
                {{ $t("Why is this required?") }}
            </b-button>
            <b-collapse id="creatorEntryFeeHowItWorks">
                <h5 class="mb-0 mt-3">
                    {{ $t("Why do we require an entry fee?") }}
                </h5>

                {{
                    $t(
                        "An entry fee is required because we need to maintain an orderly platform. One you pay your entry fee, you will be allowed back in, but if there are significant rule violations you could lose access, and be forced to pay again to enter. Having a fee helps us maintain a safe, secure and orderly platform where users follow the rules so everyone can have an enjoyable experience"
                    )
                }}

                <how-crypto-works />
            </b-collapse>

            <b-btn
                @click="payEntryFee"
                variant="primary"
                block
                pill
                class="mt-3 py-3"
            >
                <h5 class="mb-0">
                    {{ $t("Pay Entry Fee") }}
                </h5>
                <div class="small">
                    {{ $t("$25 USD in crypto") }}
                </div>
            </b-btn>
        </div>
    </div>
</template>


<script>
import { mapState } from "vuex";

import HowCryptoWorks from "@/components/utility/HowCryptoWorks";

export default {
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        payEntryFee() {
            this.saving = true;

            this.api
                .post("/nowpayments/payEntryFee")
                .then((response) => {
                    if (response?.data?.nowpaymentsInvoice?.invoiceUrl) {
                        document.location =
                            response?.data?.nowpaymentsInvoice?.invoiceUrl;
                    }
                })
                .catch(() => {})
                .then(() => {
                    this.saving = false;
                });
        },
    },
    components: {
        HowCryptoWorks,
    },
};
</script>