<template>
    <b-alert show variant="primary">
        <h3>
            <font-awesome-icon icon="fa-duotone fa-info-circle" />
            {{ $t("Content Creation Help") }}
        </h3>

        <h5 class="mt-3">{{ $t("How It Works") }}</h5>
        <div>
            {{
                $t(
                    "Answer the question with a video, the top videos will be selected to be featured on our social channels, and we'll pay you for the content"
                )
            }}
        </div>

        <h5 class="mt-3">{{ $t("What Is Expected?") }}</h5>
        <div>
            {{
                $t(
                    "Look your best, be engaging, and give unique or entertaining answers that viewers will enjoy. No explicit content is allowed"
                )
            }}
        </div>

        <h5 class="mt-3">{{ $t("How to Record Footage") }}</h5>
        <div>
            {{
                $t(
                    "We suggest recording in landscape mode, ensure clear audio, without loud background noise and give a great answer less than 60 seconds"
                )
            }}
        </div>

        <h5 class="mt-3">{{ $t("How Will this Content Be Used?") }}</h5>
        <div>
            {{
                $t(
                    "These contents will be featured on social channels and be seen by viewers to help promote our platform. By submitting content you allow us to use your content indefinitely for promotional materials"
                )
            }}
        </div>

        <h5 class="mt-3">{{ $t("How Can I Earn from Content?") }}</h5>
        <div>
            {{
                $t(
                    "Only the best videos will be selected and be paid for their content. Make sure your audio and video is clear, you are looking great and give a fun answer to have a chance to get selected for payout"
                )
            }}
        </div>

        <div class="mt-3">
            <b-btn @click="save" variant="primary" pill size="lg" block>
                {{ $t("Ok, got it") }}
            </b-btn>
        </div>
    </b-alert>
</template>

<script>
export default {
    methods: {
        save() {
            this.api.post("/creatorGuide/finishedContent").then(() => {
                this.$store.dispatch("creatorDashboard/refresh");
            });
        },
    },
};
</script>


