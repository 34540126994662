<template>
    <generic-page>
        <div class="mb-2">
            <b-btn variant="link" size="lg" class="pl-0" to="/creator/requests">
                <font-awesome-icon size="lg" icon="fa-light fa-chevron-left" />
                {{ $t("Back to Requests") }}
            </b-btn>
        </div>

        <div v-if="creatorRequest">
            <b-alert variant="warning" v-if="creatorRequest.dispute" show>
                <font-awesome-icon icon="fa-duotone fa-triangle-exclamation" />
                This request has been disputed, update content to settle the
                dispute, or decline the request if you prefer not to complete it
            </b-alert>

            <h5>Requester</h5>
            <div class="bg-white">
                <user-header
                    :disableInterest="true"
                    :standardMap="creatorRequest.user"
                >
                </user-header>
                <b-list-group>
                    <b-list-group-item @click="modal = true" action>
                        <font-awesome-icon icon="fa-duotone fa-info-circle" />
                        Dispute Rate: {{ disputeRate }}%
                    </b-list-group-item>
                </b-list-group>

                <b-modal
                    centered
                    hide-footer
                    v-model="modal"
                    title="Dispute Rate"
                >
                    <h5 class="text-primary">
                        A users dispute rate shows how often they dispute
                        requests as a percentage.
                    </h5>
                    <div class="mt-3">
                        If they dispute 2 requests in 10, they will have a 20%
                        dispute rate. A lower the dispute rate is better, and
                        more trustworthy
                    </div>
                    <div class="mt-3">
                        It does not mean you should not accept it, but they may
                        be more strict in their acceptance of the content, so
                        make sure to carefully follow the instructions
                    </div>
                </b-modal>
            </div>

            <h5 class="mt-3">Request</h5>

            <div class="border rounded">
                <div>
                    <b-table-simple borderless class="mb-0">
                        <tbody>
                            <tr>
                                <td>Credit Payout</td>
                                <td class="bg-white text-center">
                                    <coin-badge
                                        :amount="creatorRequest.payout"
                                        class="mr-1"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Local Currency</td>
                                <td class="bg-white text-center text-primary">
                                    {{ creatorRequest.payoutLocal }}
                                </td>
                            </tr>
                            <tr>
                                <td>Credits in Escrow</td>
                                <td class="bg-white text-center">
                                    <font-awesome-icon
                                        v-if="creatorRequest.escrow"
                                        class="text-success"
                                        icon="fa-light fa-check"
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </b-table-simple>

                    <b-alert v-if="creatorRequest.code" show variant="warning">
                        <div
                            class="d-flex align-items-center justify-content-between"
                        >
                            <h5>
                                {{ $t("Code Required") }}
                            </h5>
                            <div>
                                <font-awesome-icon
                                    icon="fa-light fa-arrow-right"
                                />
                            </div>
                            <h1>
                                {{ creatorRequest.code }}
                            </h1>
                        </div>

                        <div>
                            {{
                                $t(
                                    "An authenticity code is required to be visible with your content. The original photo or video you upload must be showing the 4 digit code here"
                                )
                            }}
                        </div>

                        <b-button
                            v-b-toggle.codeExplainer
                            variant="light"
                            class="mt-3"
                            block
                            pill
                        >
                            How to include code?
                        </b-button>
                        <b-collapse id="codeExplainer" class="my-3">
                            {{
                                $t(
                                    "Show the code with your content. You can write it on a piece of paper or some how show it visibly in the content so the user who requested it knows its not a fake, and is exclusively created for them. It is mandatory to include the code as part of the request"
                                )
                            }}
                            <div class="mt-3">
                                {{
                                    $t(
                                        "If your content is a video, it doesn't need to be showing during the entire video, you can show it at the beginning, and put the code away, so they know its really you"
                                    )
                                }}
                            </div>
                            <div class="mt-3 strong">
                                <font-awesome-icon
                                    icon="fa-duotone fa-triangle-exclamation"
                                />
                                {{
                                    $t(
                                        "Using fake codes with editing software will lead an an immediate account ban. If you are not comfortable producing real and original content, you can decline the request"
                                    )
                                }}
                            </div>
                            <b-img
                                class="mt-3"
                                fluid
                                src="https://d1lil0eivvt84o.cloudfront.net/explainer/request-code-example.jpg"
                            />
                        </b-collapse>
                    </b-alert>

                    <div class="p-3 bg-white border-top">
                        <div class="strong">Instructions</div>

                        <div class="my-2">
                            <h4 class="text-primary">
                                {{ creatorRequest.request }}
                            </h4>
                            <div
                                class="mt-3 d-flex align-items-center justify-content-between"
                            >
                                <b-btn
                                    @click="copyRequest"
                                    variant="light"
                                    class="border"
                                    pill
                                    size="sm"
                                >
                                    <font-awesome-icon
                                        icon="fa-duotone fa-copy"
                                    />
                                    {{ $t("Copy request text") }}
                                </b-btn>
                                <b-btn
                                    v-if="canDecline"
                                    @click="declineRequest"
                                    variant="light"
                                    class="border text-danger"
                                    pill
                                    size="sm"
                                >
                                    <font-awesome-icon
                                        icon="fa-duotone fa-thumbs-down"
                                    />

                                    {{ $t("Decline") }}
                                </b-btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-3">
                <creator-request-actions />
            </div>
        </div>
        <div v-else>
            <generic-loading />
        </div>
    </generic-page>
</template>


<script>
import UserHeader from "@/components/userHeader/UserHeader";

import CreatorRequestActions from "@/components/creator/requests/CreatorRequestActions";

import { mapState } from "vuex";

import copy from "clipboard-copy";

export default {
    data() {
        return {
            modal: false,
        };
    },
    created() {
        if (this.$route.params?.id) {
            this.$store.dispatch("creatorRequest/get", this.$route.params?.id);
        }
    },
    watch: {
        $route(to) {
            if (
                to?.params?.id !==
                this.$store.state.creatorRequest.creatorRequest?.id
            ) {
                this.$store.dispatch("creatorRequest/get", to.params.id);
            }
        },
    },
    computed: {
        canDecline() {
            if (!this.creatorRequest) {
                return false;
            }

            if (this.creatorRequest.refunded) {
                return false;
            }

            if (this.creatorRequest.fulfilledAt) {
                return false;
            }

            return true;
        },
        ...mapState("creatorRequest", [
            "creatorRequest",
            "disputeRate",
            "creatorRequestConfig",
        ]),
    },
    methods: {
        declineRequest() {
            if (
                confirm(
                    "Are you sure? Credits will be immediately refunded, and you will not earn anything."
                )
            ) {
                this.$store.dispatch("creatorRequest/decline");
            }
        },
        copyRequest() {
            copy(this.creatorRequest.request);
        },
    },
    components: {
        UserHeader,
        CreatorRequestActions,
    },
};
</script>