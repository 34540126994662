<template>
    <div>
        <div v-if="withdraw.usdtAddress" class="my-3">
            <b-alert variant="dark" show>
                <h5 class="mb-0">
                    <font-awesome-icon
                        icon="fa-duotone fa-triangle-exclamation"
                    />
                    {{ $t("Confirm Address") }}
                </h5>

                <div class="mt-3">
                    <code class="lead">
                        {{ withdraw.usdtAddress }}
                    </code>
                </div>
                <div class="small mt-2">
                    {{
                        $t(
                            "If you need to make a correction, press cancel to start over"
                        )
                    }}
                </div>
                <div class="small mt-2 text-warning">
                    <font-awesome-icon
                        icon="fa-duotone fa-triangle-exclamation"
                    />
                    {{
                        $t(
                            "Crypto transfers cannot be reversed, if sent to the wrong address you will not get your money"
                        )
                    }}
                </div>
            </b-alert>

            <b-btn
                @click="submit"
                :disabled="submitting"
                variant="primary"
                size="lg"
                class="mt-3"
                pill
                block
            >
                {{ $t("Submit Withdrawal") }}
            </b-btn>
        </div>
        <div v-else>
            <div
                v-if="onboardingUser.usdtAddress"
                class="bg-light rounded p-3 mb-3"
            >
                <div>
                    {{ $t("Previous address") }}
                </div>
                <code class="">
                    {{ onboardingUser.usdtAddress }}
                </code>
                <div class="mt-3">
                    <b-btn
                        @click="usePreviousAddress"
                        variant="primary"
                        pill
                        block
                    >
                        {{ $t("Use this address") }}
                    </b-btn>
                </div>
            </div>

            <div class="mt-3">
                {{ $t("USDT (Ethereum ERC-20) Wallet Address") }}
            </div>

            <b-alert variant="danger" show v-if="error">
                {{ error }}
            </b-alert>

            <b-input-group>
                <b-form-input
                    v-model="usdtAddress"
                    tabindex="1"
                    maxlength="252"
                    class="border-light"
                    size="lg"
                    autocomplete="off"
                    :placeholder="$t('USDT Address (Ethereum ERC-20)')"
                ></b-form-input>
                <b-input-group-append>
                    <b-btn @click="save" variant="primary" size="lg">
                        {{ $t("Save") }}
                    </b-btn>
                </b-input-group-append>
            </b-input-group>

            <b-button v-b-toggle.cryptoHelp variant="light" pill class="mt-3">
                <font-awesome-icon icon="fa-duotone fa-info-circle" size="lg" />
                {{ $t("I Need Help") }}
            </b-button>
            <b-collapse id="cryptoHelp">
                <div class="mt-3">
                    <div>
                        {{
                            $t(
                                "Copy your wallet address from your crypto provider. Often you need to click 'Receive' to reveal the address to send to"
                            )
                        }}
                    </div>
                    <div class="mt-3">
                        {{
                            $t(
                                "The address will be a bunch of random letters and numbers like this example:"
                            )
                        }}
                    </div>
                    <code class="mt-3">
                        0xA477941c7AAD6536f175ef123bf9eeD6F82A4c85
                    </code>
                    <h5 class="mt-3">
                        {{ $t("Which Network to Use?") }}
                    </h5>
                    <div>
                        {{
                            $t(
                                "Make sure you use the Ethereum (ERC-20) option when copying your wallet address"
                            )
                        }}
                    </div>
                    <h5 class="mt-3">
                        {{
                            $t(
                                "I don't have a crypto wallet, which one should I use?"
                            )
                        }}
                    </h5>
                    <div>
                        {{
                            $t(
                                "There are many crypto wallet providers, we recommend to use a well known and trusted company, here are some we recommend"
                            )
                        }}
                    </div>
                    <div class="mt-3">
                        <crypto-providers />
                    </div>
                </div>
            </b-collapse>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import CryptoProviders from "@/components/withdraw/payout/crypto/CryptoProviders";

export default {
    data() {
        return {
            usdtAddress: null,
            error: null,
        };
    },
    computed: {
        ...mapState("withdraw", ["withdraw", "submitting"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        cancelWithdraw() {
            this.$store.dispatch("withdraw/cancel");
        },
        usePreviousAddress() {
            this.usdtAddress = this.onboardingUser.usdtAddress;
            this.save();
        },
        save() {
            this.api
                .post("/withdraw/usdt", {
                    withdrawId: this.withdraw.id,
                    usdtAddress: this.usdtAddress,
                })
                .then((response) => {
                    if (response?.data?.withdraw) {
                        this.$store.dispatch(
                            "withdraw/setWithdraw",
                            response.data.withdraw
                        );
                    }
                })
                .catch((error) => {
                    if (error?.response?.data?.message) {
                        this.error = error?.response?.data?.message;
                    }
                });
        },
        submit() {
            this.$store.dispatch("withdraw/submit");
        },
    },
    components: {
        CryptoProviders,
    },
};
</script>