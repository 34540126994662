<template>
    <b-alert variant="warning" show v-if="photoUpdate?.isPending">
        <img
            class="img-fluid rounded float-right"
            :src="photoUpdate.userPhoto.small"
        />

        <h5>
            {{ $t("Pending Update") }}
        </h5>
        {{
            $t("Please allow up to 48 hours for us to review your photo change")
        }}
        <div class="mt-2 small">
            {{ $t("Photos pending update cannot be edited until reviewed") }}
        </div>
    </b-alert>
</template>

<script>
export default {
    data() {
        return {
            photoUpdate: null,
        };
    },
    created() {
        this.api.post("/photo/photoUpdate").then((response) => {
            this.photoUpdate = response?.data?.photoUpdate;
        });
    },
};
</script>