<template>
    <div>
        <b-btn
            @click="interested"
            variant="light"
            block
            class="rounded-0 mt-0"
            :size="$screen.lg ? 'lg' : 'sm'"
        >
            <div v-if="interestedInUserId(profile.id)">
                <div>
                    <font-awesome-icon
                        size="lg"
                        icon="fa-duotone fa-heart"
                        class="text-danger"
                    />
                </div>
                {{ $t("Interested") }}
            </div>
            <div v-else>
                <div>
                    <font-awesome-icon size="lg" icon="fa-light fa-heart" />
                </div>
                {{ $t("Interested") }}
            </div>
        </b-btn>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
    computed: {
        ...mapState("profile", ["profile"]),
        ...mapGetters("interest", ["interestedInUserId"]),
    },
    methods: {
        interested() {
            if (this.interestedInUserId(this.profile.id)) {
                this.$store.dispatch("interest/remove", {
                    userId: this.profile.id,
                });
            } else {
                this.$store.dispatch("interest/add", {
                    userId: this.profile.id,
                });
            }
        },
    },
    components: {},
};
</script>