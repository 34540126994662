<template>
    <div>
        <div class="d-flex align-items-center justify-content-between">
            {{ $t("Request Details") }}
            <b-btn
                v-if="onboardingUser.guideHowRequestWorksCompleted"
                @click="showInfo"
                variant="light"
                class="bg-white"
                pill
                size="sm"
            >
                <font-awesome-icon icon="fa-duotone fa-info-circle" />
                {{ $t("How it works?") }}
            </b-btn>
        </div>

        <div class="mt-1">
            <b-textarea
                v-model="request"
                tabindex="1"
                size="lg"
                maxlength="252"
                autocomplete="off"
                placeholder="Enter request instructions, what would you like from this creator?"
            ></b-textarea>
        </div>
        <div class="mt-3">
            <div
                class="d-flex align-items-center justify-content-between mt-3 mb-1"
            >
                {{ $t("Price Offered") }}
                <wallet-balance />
            </div>

            <b-form-input
                size="lg"
                v-model="price"
                :placeholder="$t('20 - 1000')"
                inputmode="numeric"
                autocorrect="off"
                autocapitalize="none"
                autocomplete="off"
            ></b-form-input>
        </div>
        <div class="mt-3">
            <div
                class="d-flex align-items-center justify-content-between mt-3 mb-1"
            >
                <b-form-checkbox v-model="requireCode" switch>
                    {{ $t("Require authenticity code") }}
                </b-form-checkbox>
            </div>
        </div>

        <div class="mt-3">
            <b-alert variant="danger" show v-if="error">
                {{ error }}
            </b-alert>

            <b-btn
                :disabled="!canSubmitRequest || saving"
                @click="submitRequest"
                variant="primary"
                size="lg"
                block
                pill
            >
                {{ $t("Submit Request") }}
            </b-btn>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import WalletBalance from "@/components/utility/WalletBalance";

export default {
    data() {
        return {
            saving: false,
            price: null,
            error: null,
            request: null,
            unsubscribeToAction: null,
            requireCode: false,
        };
    },
    mounted() {
        this.unsubscribeToAction = this.$store.subscribeAction({
            after: (action) => {
                if (action.type === "creatorRequest/start") {
                    this.price = null;
                    this.request = null;
                }
            },
        });
    },
    beforeDestroy() {
        if (this.unsubscribeToAction) {
            this.unsubscribeToAction();
        }
    },
    methods: {
        setPrice(price) {
            this.price = price;
        },
        async hideInfo() {
            await this.api.post("/userFlag/guideHowRequestWorksFinished");
            this.$store.dispatch("onboarding/status");
        },
        async showInfo() {
            await this.api.post("/userFlag/guideHowRequestWorksReset");
            this.$store.dispatch("onboarding/status");
        },
        async submitRequest() {
            this.saving = true;
            this.error = null;

            this.api
                .post("/creatorRequest/create", {
                    price: this.price,
                    request: this.request,
                    requireCode: this.requireCode,
                    creatorUserId: this.creatorStandardMap.id,
                })
                .then(() => {
                    this.$store.commit("creatorRequest/reset");
                    this.$store.commit("creatorRequest/setSentRequest", true);
                    this.$store.dispatch("onboarding/status");
                })
                .catch((error) => {
                    if (error?.response?.data?.message) {
                        this.error = error?.response?.data?.message;
                    }
                })
                .then(() => {
                    this.saving = false;
                });
        },
        closeModal() {
            this.$store.commit(
                "interface/setCreatorRequestModalVisible",
                false
            );
        },
    },
    computed: {
        canSubmitRequest() {
            if (!this.complete) {
                return false;
            }

            return true;
        },
        complete() {
            return this.price && this.request;
        },
        ...mapState("creatorRequest", ["creatorStandardMap", "sentRequest"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    components: {
        WalletBalance,
    },
};
</script>
