<template>
    <div>
        <div class="user-online-status" :class="statusClass"></div>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
    computed: {
        statusClass() {
            if (this.isUserOnline(this.standardMap.id)) {
                return "online";
            }

            const now = this.$date();
            const lastActive = this.$date(this.standardMap.lastActive);

            const differenceInMinutes = now.diff(lastActive, "minute");

            if (differenceInMinutes < 60) {
                return "away";
            }

            return "offline";
        },
        ...mapGetters("onlinePresence", ["isUserOnline"]),
        ...mapState("onlinePresence", ["onlineUserIds"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    props: ["standardMap", "size"],
};
</script>