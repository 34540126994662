// import api from "@/service/api";

const state = () => ({
    view: null,
})

const mutations = {
    // reset(state) {
    //     state.view = null
    // },
    // setView(state, value) {
    //     state.view = value
    // },
}

const actions = {
    // setView({ commit }, view) {
    //     commit('setView', view);
    // },
}


const getters = {
    isRestricted(state, getters, rootState) {
        if (!rootState.onboarding?.user?.creator) {
            return false;
        }

        if (rootState.onboarding?.user?.creatorAccountRestricted) {
            return true;
        }

        return false;
    },
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}

