<template>
    <b-btn
        to="/creator/requests"
        class="bg-white"
        variant="light"
        block
        size="lg"
    >
        <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
                <div class="mr-2">
                    <font-awesome-icon
                        class="small-avatar branded"
                        icon="fa-duotone fa-hand-holding-dollar"
                        size="lg"
                    />
                </div>
                {{ $t("Requests") }}
            </div>
            <div v-if="dashboard?.requestCount" class="small text-warning">
                <font-awesome-icon icon="fa-duotone fa-triangle-exclamation" />
                {{ dashboard?.requestCount }}
                {{ $t("Pending") }}
            </div>
        </div>
    </b-btn>
</template>


<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
        ...mapState("creatorDashboard", ["dashboard"]),
    },
};
</script>