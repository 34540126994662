<template>
    <div>
        <b-alert variant="danger" show v-if="error">
            {{ error }}
        </b-alert>

        <b-alert variant="primary" v-if="uploadingUrl" show>
            <h5 class="d-flex align-items-center mb-0">
                <b-spinner class="mr-1" small variant="dark" />
                Processing Video...
            </h5>
            <div class="small mt-2 d-flex align-items-center">
                This can take a few minutes depending on the length of the video
            </div>
            <div class="mt-4">
                <video
                    v-if="uploadingUrl"
                    :autoplay="true"
                    :muted="true"
                    :controls="false"
                    :loop="true"
                    :src="uploadingUrl"
                    playsinline
                    class="img-fluid"
                ></video>
            </div>
        </b-alert>
        <div v-else>
            <div class="d-flex align-items-center justify-content-center mt-3">
                <universal-uploader
                    :contentOffer="true"
                    @uploadSuccess="uploadSuccess"
                    class="text-truncate"
                    :clientAllowedFormats="['video']"
                >
                    <b-btn
                        variant="primary"
                        class="d-flex align-items-center justify-content-center"
                        pill
                        size="lg"
                    >
                        <font-awesome-icon
                            class="mr-1"
                            icon="fa-duotone fa-video"
                        />
                        Upload Video
                    </b-btn>
                </universal-uploader>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.uploading {
    opacity: 0.5;
}
</style>


<script>
import UniversalUploader from "@/components/universalUploader/UniversalUploader";
import { mapState } from "vuex";

export default {
    data() {
        return {
            error: null,
            uploadingUrl: null,
        };
    },
    computed: {
        ...mapState("contentOffer", ["contentOffer", "uploading"]),
    },
    methods: {
        uploadSuccess(url, data) {
            this.error = null;
            this.uploadingUrl = url;

            this.$store.commit("contentOffer/setUploading", true);

            this.api
                .post(
                    `/contentOffer/${this.contentOffer.id}/uploadCloudinary`,
                    {
                        data,
                    }
                )
                .then(() => {
                    this.$store.dispatch("contentOffer/refresh");
                })
                .catch((error) => {
                    this.error = error.response.data.message;
                    this.uploadingUrl = null;
                })
                .then(() => {
                    this.$store.commit("contentOffer/setUploading", false);
                });
        },
        publishPost() {
            //
        },
    },
    components: {
        UniversalUploader,
    },
};
</script>

