<template>
    <div class="text-left">
        <div>
            {{
                $t(
                    "Are you here to meet and chat with creators, or to earn money by engaging with others?"
                )
            }}
        </div>

        <div v-if="role">
            <b-alert variant="warning" show class="mb-0 mt-3">
                <h5>
                    {{ $t("Confirm your selection") }}
                </h5>

                <div class="bg-white rounded p-3 border mt-3">
                    <div>
                        <h5>
                            {{ selectedRole.title }}
                        </h5>
                        <div>
                            {{ selectedRole.description }}
                        </div>
                    </div>
                </div>

                <b-btn
                    pill
                    @click="role = null"
                    variant="secondary"
                    block
                    size="lg"
                    class="mt-3"
                >
                    {{ $t("Edit Role") }}
                </b-btn>
            </b-alert>

            <div class="mt-4">
                <div class="text-warning">
                    <div>
                        {{
                            $t(
                                "Make sure you select the correct option, this setting cannot be changed once selected"
                            )
                        }}
                    </div>
                </div>
                <b-btn
                    pill
                    @click="save"
                    variant="primary"
                    block
                    size="lg"
                    class="mt-3"
                >
                    {{ $t("Continue") }}
                </b-btn>
            </div>
        </div>
        <div v-else>
            <div class="mt-3">
                <b-btn
                    @click="role = option.key"
                    :key="option.key"
                    v-for="option in options"
                    block
                    variant="light"
                    class="btn-brand text-left p-3"
                >
                    <div
                        class="d-flex align-items-center justify-content-between"
                    >
                        <div>
                            <h5>
                                {{ option.title }}
                            </h5>
                            <div>
                                {{ option.description }}
                            </div>
                        </div>
                        <div>
                            <font-awesome-icon
                                size="3x"
                                icon="fa-light fa-chevron-right"
                            />
                        </div>
                    </div>
                </b-btn>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    data() {
        return {
            role: null,
            options: [
                {
                    key: "customer",
                    title: this.$t("Customer"),
                    description: this.$t(
                        "You want to connect with and chat with creators"
                    ),
                },
                {
                    key: "creator",
                    title: this.$t("Creator"),
                    description: this.$t(
                        "You want to earn money by chatting and engaging with others"
                    ),
                },
            ],
        };
    },
    computed: {
        selectedRole() {
            return this.options.find((option) => option.key === this.role);
        },
    },
    methods: {
        async save() {
            this.api
                .post("/creator/role", {
                    role: this.role,
                })
                .then(() => {
                    this.$store.dispatch("onboarding/status");
                });
        },
    },
};
</script>