<template>
    <generic-page>
        <back-to-dashboard />

        <div class="d-flex align-items-center justify-content-between mt-3">
            <div class="d-flex align-items-center">
                <h5 class="mb-0 mr-2">
                    {{ $t("Platform Rules") }}
                </h5>
            </div>
        </div>

        <div class="py-2">
            <div v-if="loading">
                <generic-loading />
            </div>
            <div v-else>
                <b-list-group>
                    <b-list-group-item :key="rule.id" v-for="rule in rules">
                        <div>
                            <h5 class="text-danger">
                                {{ rule.title }}
                            </h5>

                            <div>
                                {{ rule.description }}
                            </div>
                        </div>
                        <b-button
                            v-if="rule.explainer"
                            class="mt-3"
                            v-b-toggle="`rule-explainer-${rule.id}`"
                            variant="light"
                            size="sm"
                            pill
                        >
                            <font-awesome-icon
                                icon="fa-duotone fa-info-circle"
                            />
                            {{ $t("More Information") }}
                        </b-button>
                        <b-collapse
                            :id="`rule-explainer-${rule.id}`"
                            class="mt-2"
                        >
                            <preserve-content-spacing
                                :content="rule.explainer"
                            />
                        </b-collapse>
                    </b-list-group-item>
                </b-list-group>
            </div>
        </div>
    </generic-page>
</template>

<script>
import BackToDashboard from "@/components/utility/BackToDashboard";
import PreserveContentSpacing from "@/components/utility/PreserveContentSpacing";

export default {
    data() {
        return {
            rules: null,
            loading: false,
        };
    },
    created() {
        this.refresh();
    },
    methods: {
        refresh() {
            this.loading = true;

            this.api
                .post("/rule/index")
                .then((response) => {
                    this.rules = response?.data?.rules;
                })
                .catch(() => {})
                .then(() => {
                    this.loading = false;
                });
        },
    },
    components: {
        BackToDashboard,
        PreserveContentSpacing,
    },
};
</script>