<template>
    <div>
        <b-modal title="Jackpot!" hide-footer v-model="modal" centered>
            <div class="text-center">
                <h1 class="text-dark">Jackpot!</h1>
                <h5 class="text-secondary">
                    Win the weekly jackpot for top inviters
                </h5>
            </div>

            <h5 class="mt-3">How it Works?</h5>
            The jackpot is paid out at the end of every week. The top users with
            the most invite rewards for the week win the jackpot and instantly
            get the credits transferred to their wallet

            <h5 class="mt-3">Who to Invite?</h5>
            Invite customers who would be interested in a platform like this and
            willing to buy credits. Every time they pay for credits, you earn a
            reward. Invite your friends to be creators, when they withdraw
            credits, you'll earn a reward as well

            <h5 class="mt-3">How are the rewards counted?</h5>
            Each reward you earn during the week will get you 1 point towards
            winning the jackpot for both men and women. If they continue to earn
            rewards next week, those will count for the next week's jackpot, so
            the more people you invite, the higher your chances will be to win
            the jackpot, even in the future

            <h5 class="mt-3">What is the purpose of the jackpot?</h5>
            To reward the top users who earned the most invite rewards in the
            last week. We encourage inviters to bring as many people as possible
            to maximize earnings

            <div v-if="onboardingUser.creator" class="mt-3">
                <creator-invite-guides />
            </div>
        </b-modal>

        <b-btn
            @click="modal = true"
            variant="light"
            pill
            class="border"
            size="sm"
        >
            <font-awesome-icon icon="fa-duotone fa-info-circle" />
            How to Win
        </b-btn>
    </div>
</template>


<script>
import { mapState } from "vuex";

import CreatorInviteGuides from "@/components/creator/guide/view/CreatorInviteGuides";

export default {
    data() {
        return {
            modal: false,
        };
    },
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    components: {
        CreatorInviteGuides,
    },
};
</script>