<template>
    <div>
        <h5 class="mb-3 text-center">
            {{ $t("Choose Refill Package") }}
        </h5>
        <table class="table table-lg table-striped border mt-2">
            <thead>
                <th>
                    {{ $t("Package") }}
                </th>
                <th class="text-center align-middle">
                    {{ $t("Checkout") }}
                </th>
            </thead>
            <tbody>
                <tr :key="price.id" v-for="price in creditRefillPrices">
                    <td class="text-left">
                        <div>
                            <h5 class="mb-0">
                                <font-awesome-icon
                                    icon="fa-duotone fa-coin"
                                    class="text-warning"
                                />
                                {{ price.credits }}
                                Credits
                            </h5>
                            <div class="text-secondary">
                                {{ price.label }}
                            </div>
                        </div>
                    </td>
                    <td class="text-center align-middle">
                        <b-btn
                            @click="selectPackage(price)"
                            pill
                            block
                            variant="primary"
                        >
                            {{ $t("Buy") }}
                            ${{ price.amount }}
                            <div class="small text-opaque">
                                {{ price.localPrice }}
                            </div>
                        </b-btn>
                    </td>
                </tr>
            </tbody>
        </table>

        <b-button v-b-toggle.howCreditsWork variant="light" block size="lg" pill>
            <font-awesome-icon icon="fa-duotone fa-info-circle" />
            {{ $t("How Credits Work") }}
        </b-button>
        <b-collapse id="howCreditsWork">
            <div
                class="alert alert-primary border-0 rounded-0 text-left mb-0 mt-3"
            >
                <h5 class="">
                    {{ $t("Simple & Transparent Billing") }}
                </h5>
                <div class="mt-1">
                    <font-awesome-icon class="mr-1" icon="fa-light fa-check" />
                    {{ $t("One-time payment") }}
                </div>
                <div class="mt-1">
                    <font-awesome-icon class="mr-1" icon="fa-light fa-check" />
                    {{ $t("Credits never expire") }}
                </div>
                <div class="mt-1">
                    <font-awesome-icon class="mr-1" icon="fa-light fa-check" />
                    {{ $t("No hidden fees or recurring charges") }}
                </div>
                <div class="mt-1">
                    <font-awesome-icon class="mr-1" icon="fa-light fa-check" />
                    {{ $t("Only pay for what you use") }}
                </div>

                <h5 class="mt-4">
                    {{ $t("Straightforward Pricing") }}
                </h5>
                <b-list-group>
                    <b-list-group-item
                        class="d-flex align-items-center justify-content-between"
                    >
                        {{ $t("Chatting") }}
                        <b-badge pill variant="light">
                            <font-awesome-icon
                                icon="fa-duotone fa-coin"
                                class="text-warning"
                                size="lg"
                            />
                            {{ prices.message }}
                            {{ $t("per message") }}
                        </b-badge>
                    </b-list-group-item>
                    <b-list-group-item
                        class="d-flex align-items-center justify-content-between"
                    >
                        {{ $t("Video Calling") }}
                        <b-badge pill variant="light">
                            <font-awesome-icon
                                icon="fa-duotone fa-coin"
                                class="text-warning"
                                size="lg"
                            />
                            {{ prices.call }}
                            {{ $t("per minute") }}
                        </b-badge>
                    </b-list-group-item>
                </b-list-group>
            </div>
        </b-collapse>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
        ...mapState("options", ["creditRefillPrices", "prices"]),
    },
    methods: {
        closeWallet() {
            this.$store.commit("interface/setUserWalletModalVisible", false);
        },
        selectPackage(price) {
            this.$store.commit("wallet/setPrice", price);
        },
        backupPayments() {},
    },
};
</script>