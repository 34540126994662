<template>
    <b-btn
        to="/creator/contentOffer/index"
        class="bg-white"
        variant="light"
        block
        size="lg"
    >
        <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
                <div class="mr-2">
                    <font-awesome-icon
                        class="small-avatar branded"
                        icon="fa-duotone fa-photo-film"
                        size="lg"
                    />
                </div>
                {{ $t("Content") }}
            </div>
            <div v-if="creatorUser.contentOfferCount" class="text-warning">
                {{ creatorUser.contentOfferCount }} new offers
            </div>
        </div>
    </b-btn>
</template>


<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("creatorDashboard", ["creatorUser"]),
    },
};
</script>