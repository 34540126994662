<template>
    <generic-page>
        <div v-if="withdraw" class="p-3 bg-white">
            <div class="d-flex align-items-center justify-content-between mb-3">
                <h5>
                    {{ $t("Creating Withdrawal") }}
                </h5>
                <b-btn
                    @click="cancelWithdrawal"
                    variant="light"
                    class="text-danger"
                    pill
                >
                    {{ $t("Cancel") }}
                </b-btn>
            </div>

            <div v-if="onboardingUser.unpaidFineCount">
                <withdraw-unpaid-fines />
            </div>
            <div v-else-if="withdraw.acceptFee">
                <withdraw-fulfillment />
            </div>
            <div v-else>
                <withdraw-preview />
            </div>

            <b-btn
                v-if="withdraw.userPayee"
                @click="submitWithdraw"
                variant="primary"
                class="mt-3"
            >
                {{ $t("Submit") }}
            </b-btn>
        </div>
        <div v-else>
            <generic-loading />
        </div>
    </generic-page>
</template>

<script>
import { mapState } from "vuex";

import WithdrawUnpaidFines from "@/components/withdraw/WithdrawUnpaidFines";
import WithdrawFulfillment from "@/components/withdraw/WithdrawFulfillment";
import WithdrawPreview from "@/components/withdraw/WithdrawPreview";

export default {
    mounted() {
        this.$store.dispatch("withdraw/refresh");
    },
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
        ...mapState("withdraw", ["withdraw", "payouts"]),
    },
    methods: {
        updatePayout(payout) {
            this.$store.dispatch("withdraw/updatePayout", payout);
        },
        cancelWithdrawal() {
            if (
                confirm(
                    "Are you sure you want to cancel this withdrawal? You will need to start over."
                )
            ) {
                this.$store.dispatch("withdraw/cancel");
            }
        },
        submitWithdraw() {
            this.$store.dispatch("withdraw/submit");
        },
    },
    components: {
        WithdrawUnpaidFines,
        WithdrawFulfillment,
        WithdrawPreview,
    },
};
</script>