import pusherStorage from "@/utility/pusherStorage";
import Pusher from "pusher-js";

// Pusher.logToConsole = true

const state = () => ({
    initialized: false,
    queuedDispatch: [],
})

const mutations = {
    initialized(state) {
        state.initialized = true
    },
    appendQueuedDispatch(state, value) {
        state.queuedDispatch.push(value)
    },
}

const fromCurrentConversation = (rootState, data) => {
    return rootState.profile?.profile?.id === data.fromUserId
}

const toCurrentConversation = (rootState, data) => {
    return rootState.profile?.profile?.id === data.toUserId
}

const actions = {
    messageReflection({ commit, rootState }, data) {
        commit("conversation/removePending", data.instanceId, { root: true });
        commit("message/inboundMessage", data.message, { root: true });

        if (toCurrentConversation(rootState, data)) {
            commit("conversation/prependMessage", data.message, { root: true });
        }
    },
    seen({ commit, rootState }, data) {
        if (fromCurrentConversation(rootState, data)) {
            commit('conversation/markAllSeen', null, { root: true });
        }
    },
    message({ commit, dispatch, rootState }, data) {
        if (fromCurrentConversation(rootState, data)) {
            commit("conversation/prependMessage", data.message, { root: true });

            if (rootState.system.visible) {
                dispatch('conversation/seen', null, { root: true });
            } else {
                commit('conversation/setCurrentConverstionPendingMarkSeen', null, { root: true });
            }
        } else {
            if (rootState.onboarding?.user?.soundNewMessage) {
                dispatch("audioAlert/chime", "new", { root: true });
            }

            const getNotificationMessageContent = (data) => {
                if (data.message.type === 'text') {
                    return this._vm.$t("New Message");
                }

                if (data.message.type === 'sentCredit') {
                    return this._vm.$t("Sent you money");
                }

                if (data.message.type === 'photo') {
                    return this._vm.$t("New Photo");
                }

                if (data.message.type === 'video') {
                    return this._vm.$t("New Video");
                }
            }

            if (
                // calling has its own dedicated modal, no need to show toast
                data.message.type !== 'call'
                && rootState.system.visible
            ) {
                dispatch("notification/append", {
                    title: data.standardMap.name,
                    photo: data.standardMap.photo,
                    message: getNotificationMessageContent(data),
                    click: () => {
                        dispatch("profile/viewProfile", {
                            userId: data.message.userId,
                            profile: data.standardMap,
                            view: 'conversation',
                        }, { root: true });
                    },
                }, { root: true });
            }
        }

        commit("message/inboundMessage", data.message, { root: true });
    },
    activity({ dispatch }) {
        dispatch("activity/refresh", null, { root: true });
    },
    call({ dispatch }, data) {
        dispatch("call/handleEvent", data, { root: true });
    },
    interest({ dispatch, rootState }, data) {
        if (rootState.system.visible) {
            dispatch("notification/append", {
                title: data.standardMap.name,
                photo: data.standardMap.photo,
                message: this._vm.$t("I'm interested"),
                click: () => {
                    dispatch("profile/viewProfile", {
                        userId: data.standardMap.id,
                        profile: data.standardMap,
                    }, { root: true });
                },
            }, { root: true });
        }

        dispatch("interest/refresh", null, { root: true });
    },
    typing({ dispatch }, data) {
        dispatch("message/typing", data.userId, { root: true });
    },
    initialize({ state, commit, dispatch, rootState }) {
        if (state.initialized) {
            return;
        }

        pusherStorage.pusherClient = new Pusher(rootState.options.pusher.key, {
            cluster: rootState.options.pusher.cluster,
            encrypted: true,
            authEndpoint: rootState.options.pusher.authEndpoint,
            auth: {
                headers: {
                    Authorization: `Bearer ${rootState.user.authToken}`,
                },
            },
        });

        commit('initialized')
        dispatch('executeQueuedDispatch')

        if (process.env.NODE_ENV !== 'production') {
            // Pusher.logToConsole = true;
        }

        const channel = pusherStorage.pusherClient.subscribe(rootState.onboarding.user.privateChannel);

        const routeMessageToAction = (data) => {
            // next generation, kiss
            if (data.type === "call") {
                dispatch('call', data);
            }


            ///////////////////////////////////////////////////
            // legacy below
            ///////////////////////////////////////////////////

            if (data.type === "message-reflection") {
                dispatch('messageReflection', data);
            }

            if (data.type === "message") {
                dispatch('message', data);
            }

            if (data.type === "seen") {
                dispatch('seen', data);
            }

            if (data.type === "interest") {
                dispatch('interest', data);
            }

            if (data.type === "activity") {
                dispatch('activity', data);
            }

            if (data.type === "interest-reflection") {
                dispatch("interest/refresh", null, { root: true });
            }

            if (data.type === "typing") {
                dispatch('typing', data);
            }

            if (data.type === "user-blocked") {
                commit("user/blockUserId", data.userId, { root: true });
            }

            if (data.type === "user-blocked-reflection") {
                commit("user/blockUserId", data.userId, { root: true });
            }

            if (data.type === "photo-approved") {
                dispatch("onboarding/status", null, { root: true });

                commit('photo/removePendingPhoto', data.instanceId, { root: true })
            }

            if (data.type === "private-photo-processed") {
                dispatch("onboarding/status", null, { root: true });

                commit('photo/removePendingPhoto', data.instanceId, { root: true })
            }

            if (data.type === "create-post-refresh") {
                dispatch("creatorPostEdit/refresh", null, { root: true });
            }

            if (data.type === "creator-video-refresh") {
                dispatch("creatorVideo/refreshCreatorVideo", null, { root: true });
            }

            if (data.type === "content-offer-refresh") {
                dispatch("contentOffer/refreshContentOffer", null, { root: true });
            }

            if (data.type === "credit" && data.event === "balanceUpdated") {
                dispatch("onboarding/status", null, { root: true });
            }

            if (data.type === "credit" && data.event === "chatEarningsUpdated") {
                dispatch("conversation/refreshEarnings", null, { root: true });
            }

            if (data.type === "photo-rejected") {
                dispatch("onboarding/status", null, { root: true });

                commit("photo/appendPhotoError", {
                    instanceId: data.instanceId,
                    message: data.rejectFeedback,
                }, { root: true });
            }

            if (data.type === "user-upgraded") {
                dispatch('options/refresh', null, { root: true });
                dispatch("onboarding/status", null, { root: true });
            }

            if (data.type === "creatorAuthenticity") {
                dispatch("onboarding/status", null, { root: true });
            }
        };

        channel.bind("notify", (data) => {
            if (data?.pusherNextVersion) {
                routeMessageToAction(data.pusherNextVersion)
            } else {
                // ignore old payloads, only listen to this key
                // once old client is forcibly deprecated, this entire key can be removed

                console.log('legacy payload, disregarding', data)
            }
        });
    },
    queueDispatch({ state, dispatch, commit }, { action, payload } = {}) {
        if (state.initialized) {
            dispatch(action, payload)
            return;
        }

        commit('appendQueuedDispatch', { action, payload })
    },
    executeQueuedDispatch({ state, dispatch }) {
        if (!state.queuedDispatch.length) {
            return;
        }

        state.queuedDispatch.forEach(async ({ action, payload }) => {
            if (action) {
                await dispatch(action, payload)
            }
        })
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}



