<template>
    <div>
        <b-alert v-if="scheduledDeleteError" show variant="danger">
            <h5>Deletion Scheduled</h5>
            Withdrawals cannot be submitted while deletion is scheduled. If
            deletion occurs during processing, you may not get your money. To
            withdraw, reactivate your account, and delete once the withdrawal
            has fully completed, and the money is already in your bank for 48
            hours

            <b-btn
                class="mt-3"
                @click="reactivate"
                variant="primary"
                block
                pill
            >
                Reactivate Account
            </b-btn>
        </b-alert>
        <div v-if="withdraw.type === 'wise'">
            <payout-wise />
        </div>
        <div v-if="withdraw.type === 'btc'">
            <payout-bitcoin />
        </div>
        <div v-if="withdraw.type === 'usdt'">
            <payout-usdt />
        </div>
        <div v-if="withdraw.type === 'usdc'">
            <payout-usdc />
        </div>
        <div v-if="withdraw.type === 'capitalist'">
            <payout-capitalist />
        </div>
        <div v-if="withdraw.type === 'paypal'">
            <payout-paypal />
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import PayoutWise from "@/components/withdraw/payout/PayoutWise";
import PayoutBitcoin from "@/components/withdraw/payout/PayoutBitcoin";
import PayoutUsdt from "@/components/withdraw/payout/PayoutUsdt";
import PayoutUsdc from "@/components/withdraw/payout/PayoutUsdc";
import PayoutPaypal from "@/components/withdraw/payout/PayoutPaypal";
import PayoutCapitalist from "@/components/withdraw/payout/PayoutCapitalist";

export default {
    computed: {
        ...mapState("withdraw", ["withdraw", "scheduledDeleteError"]),
    },
    methods: {
        reactivate() {
            this.$store.dispatch("delete/cancel");
            this.$store.commit("withdraw/setScheduledDeletedError", false);
        },
    },
    components: {
        PayoutWise,
        PayoutBitcoin,
        PayoutUsdt,
        PayoutUsdc,
        PayoutPaypal,
        PayoutCapitalist,
    },
};
</script>