<template>
    <generic-page>
        <div v-if="creatorUserMeta && !creatorUserMeta?.creatorGuideContent">
            <content-offer-explainer />
        </div>
        <div v-else>
            <div
                class="d-flex align-items-center justify-content-between mt-3 mb-2"
            >
                <h5 class="mb-0">{{ $t("Content Offers") }}</h5>
                <div class="d-flex align-items-center">
                    <b-dropdown
                        text="Filter"
                        variant="secondary"
                        right
                        class="rounded-pill bg-white mr-2"
                    >
                        <b-dropdown-item @click="setFilter('Not Interested')">
                            Not Interested
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </div>

            <b-list-group>
                <b-list-group-item
                    class="d-flex justify-content-between align-items-center fake-link"
                    :key="contentOffer.id"
                    v-for="contentOffer in contentOffers"
                    action
                    :to="`/creator/contentOffer/view/${contentOffer.id}`"
                >
                    <div>
                        <div class="text-dark">
                            {{ contentOffer.title }}
                        </div>
                        <div class="small text-secondary">
                            {{ contentOffer.description }}
                        </div>
                    </div>
                    <div>
                        <coin-badge
                            class="bg-white"
                            :amount="contentOffer.reward"
                            label="Reward"
                        />
                    </div>
                </b-list-group-item>
            </b-list-group>
        </div>
    </generic-page>
</template>



<script>
import { mapState } from "vuex";

import ContentOfferExplainer from "@/components/creator/contentOffer/ContentOfferExplainer";

export default {
    data() {
        return {
            contentOffer: null,
            contentOffers: null,
            filter: null,
        };
    },
    created() {
        this.refresh();
        this.$store.dispatch("creatorDashboard/refresh");
    },
    computed: {
        ...mapState("creatorDashboard", ["creatorUserMeta"]),
    },
    methods: {
        setFilter(filter) {
            this.filter = filter;
            this.refresh();
        },
        refresh() {
            this.api
                .post("/contentOffer/index", {
                    filter: this.filter,
                })
                .then((response) => {
                    this.contentOffers = response?.data?.contentOffers;
                });
        },
        setContentOffer(contentOffer) {
            this.contentOffer = contentOffer;
        },
    },
    components: {
        ContentOfferExplainer,
    },
};
</script>