<template>
    <div>
        <b-alert variant="primary" show class="mb-0">
            <h5>
                {{ $t("How requests work?") }}
            </h5>
            <div>
                {{
                    $t(
                        "Send credits to a creator in exchange for your custom requests. They will be presented with your request and price, if they accept and complete the request the credits will be transferred to their wallet"
                    )
                }}
            </div>

            <h5 class="mt-3">
                {{ $t("What if they don't complete the request?") }}
            </h5>
            <div>
                {{
                    $t(
                        "Incomplete requests will automatically be refunded if the creator does not complete the request, they have 1 week to complete any pending requests or the credits will be transferred back to your wallet"
                    )
                }}
            </div>

            <h5 class="mt-3">
                {{ $t("What is the authenticity code option?") }}
            </h5>
            <div>
                {{
                    $t(
                        "The authenticity code option will generate a unique code that the user should include with their content, usually by showing the code as part of their photo/videos so you know its original, genuine, and exclusively created for you. We recommend to require the code with all requests"
                    )
                }}
            </div>

            <h5 class="mt-3">
                {{ $t("What if I'm not satisfied with the result?") }}
            </h5>
            <div>
                {{
                    $t(
                        "If you don't accept the content, you can create a dispute, which will give them a chance to correct their submission. If after disputing you still don't get what you're looking for, you will be refunded"
                    )
                }}
            </div>

            <b-btn
                variant="primary"
                @click="hideInfo"
                pill
                class="mt-3"
                block
                size="lg"
            >
                {{ $t("Continue") }}
            </b-btn>
        </b-alert>
    </div>
</template>

<script>
export default {
    methods: {
        async hideInfo() {
            await this.api.post("/userFlag/guideHowRequestWorksFinished");
            this.$store.dispatch("onboarding/status");
        },
    },
};
</script>