<template>
    <generic-page>
        <affiliate-share-url class="mt-2" />

        <div class="mt-2">
            <affiliate-earnings-summary />
            <affiliate-payouts-summary v-if="highValue" />
            <affiliate-bonus-summary v-if="highValue" />
            <affiliate-links-summary v-if="highValue" />
            <affiliate-qrcode-summary />
            <affiliate-help-summary v-if="false" />
        </div>
    </generic-page>
</template>

<script>
import { mapState } from "vuex";

// legacy, need to update paths to use new dir structure
import AffiliateBonusSummary from "@/components/affiliate/summary/AffiliateBonusSummary";
import AffiliateEarningsSummary from "@/components/affiliate/summary/AffiliateEarningsSummary";
import AffiliateLinksSummary from "@/components/affiliate/summary/AffiliateLinksSummary";
import AffiliateQrcodeSummary from "@/components/affiliate/summary/AffiliateQrcodeSummary";
import AffiliateHelpSummary from "@/components/affiliate/summary/AffiliateHelpSummary";
import AffiliatePayoutsSummary from "@/components/affiliate/summary/AffiliatePayoutsSummary";

import AffiliateShareUrl from "@/components/affiliate/links/AffiliateShareUrl";

export default {
    computed: {
        ...mapState("affiliate", ["highValue", "conversionExplainer"]),
    },
    components: {
        AffiliateEarningsSummary,
        AffiliateBonusSummary,
        AffiliateLinksSummary,
        AffiliateQrcodeSummary,
        AffiliateHelpSummary,
        AffiliatePayoutsSummary,
        AffiliateShareUrl,
    },
};
</script>