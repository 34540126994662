import api from "@/service/api";

const state = () => ({
    complete: false,
    recording: false,
    recordingUploading: false,
    creatorAuthenticity: null,
})

const mutations = {
    reset(state) {
        state.complete = false
        state.recording = false
        state.recordingUploading = false
    },
    setComplete(state, value) {
        state.complete = value;
    },
    setRecording(state, value) {
        state.recording = value;
    },
    setRecordingUploading(state, value) {
        state.recordingUploading = value;
    },
    setCreatorAuthenticity(state, value) {
        state.creatorAuthenticity = value;
    },
}

const actions = {
    refresh({ commit }) {
        api
            .post("/creatorAuthenticity/current")
            .then((response) => {
                commit('setCreatorAuthenticity', response.data.creatorAuthenticity)
            });
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
