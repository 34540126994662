import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getDatabase } from 'firebase/database'

const firebaseConfig = {
    appId: "1:25569898344:web:0e52f9e16ac5861db9b0dd",
    apiKey: "AIzaSyAibL1kz9BzEC_yz-pldCzMd74HLArvqlQ",
    projectId: "findmate-cordova",
    authDomain: "findmate-cordova.firebaseapp.com",
    databaseURL: "https://findmate-cordova.firebaseio.com",
    measurementId: "G-EB199JNZV6",
    storageBucket: "findmate-cordova.appspot.com",
    messagingSenderId: "25569898344",
};

const firebaseApp = initializeApp(firebaseConfig)

const auth = getAuth(firebaseApp)
const db = getDatabase(firebaseApp)

export {
    firebaseApp,
    auth,
    db,
}
