<template>
    <div v-if="isLoggedIn && canInterest">
        <b-btn
            v-if="interestedInUserId(standardMap.id)"
            variant="light"
            pill
            class="d-flex align-items-center bg-white border-white"
        >
            <div class="small">
                <font-awesome-icon
                    size="sm"
                    icon="fa-light fa-check"
                    class="text-primary"
                />
                <span class="text-secondary">
                    {{ $t("Interested") }}
                </span>
            </div>
        </b-btn>
        <b-btn
            v-else
            @click="interested"
            variant="light"
            pill
            class="d-flex align-items-center btn-brand"
        >
            {{ $t("Interested") }}
        </b-btn>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
    computed: {
        canInterest() {
            if (this.standardMap.id === this.onboardingUser.id) {
                return false;
            }

            return true;
        },
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
        ...mapGetters("interest", ["interestedInUserId"]),
        ...mapGetters("user", ["isLoggedIn"]),
    },
    methods: {
        interested() {
            if (this.hasInterest) {
                this.$store.dispatch("interest/remove", {
                    userId: this.standardMap.id,
                });
            } else {
                this.$store.dispatch("interest/add", {
                    userId: this.standardMap.id,
                });
            }
        },
    },
    props: ["standardMap"],
};
</script>