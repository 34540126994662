<template>
    <div>
        <div v-if="isRestricted">
            <entry-fee />
        </div>
        <div v-else>
            <profile-conversation-messages />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import ProfileConversationMessages from "@/components/profile/conversation/ProfileConversationMessages";
import EntryFee from "@/components/creator/entryFee/EntryFee";

export default {
    computed: {
        ...mapState("profile", ["profileError", "profile"]),
        ...mapGetters("creator", ["isRestricted"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    components: {
        EntryFee,
        ProfileConversationMessages,
    },
};
</script>
