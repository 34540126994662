<template>
    <generic-page>
        <back-to-dashboard />

        <div class="d-flex align-items-center justify-content-between">
            <h5 class="mb-0">Fines</h5>

            <wallet-balance />
        </div>

        <b-alert v-if="error" show variant="danger">
            {{ error }}
        </b-alert>

        <b-list-group class="mt-2">
            <b-list-group-item
                v-for="{ userFine, rule } in userFines"
                :key="userFine.id"
            >
                <h5 class="d-flex align-items-center justify-content-between">
                    <span class="text-danger">
                        {{ rule.title }}
                    </span>
                    <coin-badge :amount="userFine.amount" />
                </h5>

                <div>
                    {{ rule.description }}
                </div>

                <div
                    class="d-flex align-items-center justify-content-between mt-2"
                >
                    <div class="small">
                        {{ userFine.createdRelative }}
                    </div>

                    <b-btn
                        :disabled="payingFineId === userFine.id"
                        variant="primary"
                        pill
                        @click="payFine(userFine)"
                    >
                        Pay Fine
                    </b-btn>
                </div>
            </b-list-group-item>
        </b-list-group>

        <b-alert variant="warning" show class="mt-3">
            <h5>
                <font-awesome-icon icon="fa-duotone fa-triangle-exclamation" />
                Do not talk about your fine with others
            </h5>
            Do not share details about your fine, withdrawals, or credits with
            other users. These are private matters. If you have questions,
            contact support. Asking another user for credits to pay your fine
            will result in an instant ban

            <h5 class="mt-3">Why do I have a fine?</h5>
            Fines are given to stop behavior that causes problems on our
            platform. Pay your fine and follow the rules to keep using the
            platform. Repeated rule-breaking can lead to a ban

            <h5 class="mt-3">Am I banned?</h5>
            No, your account is still active. However, this violation is now on
            your record. If you break the rules again, you may be banned. Please
            follow the rules to avoid this
        </b-alert>
    </generic-page>
</template>

<script>
import BackToDashboard from "@/components/utility/BackToDashboard";
import WalletBalance from "@/components/utility/WalletBalance";

export default {
    data() {
        return {
            payingFineId: null,
            userFines: null,
            error: null,
        };
    },
    mounted() {
        this.refresh();
    },
    methods: {
        payFine(userFine) {
            this.payingFineId = userFine.id;
            this.error = null;

            this.api
                .post(`/userFine/${userFine.id}/pay`)
                .then(() => {
                    this.refresh();
                    this.$store.dispatch("onboarding/status");
                })
                .catch((error) => {
                    if (error?.response?.data?.code === "insufficientBalance") {
                        this.error =
                            "Not enough credits. If you have a pending withdrawal, you can cancel to transfer the credits back to your wallet";
                    }
                })
                .then(() => {
                    this.payingFineId = null;
                });
        },
        refresh() {
            this.api.post("/userFine/index").then((response) => {
                this.userFines = response.data.userFines;
            });
        },
    },
    components: {
        BackToDashboard,
        WalletBalance,
    },
};
</script>