<template>
    <div>
        <b-alert variant="danger" show v-if="error">
            {{ error }}
        </b-alert>

        <div class="d-flex justify-content-between align-items-center">
            <div>
                {{ $t("Card Number") }}
            </div>
            <div>
                <b-btn
                    v-if="onboardingUser.authorizeProfile"
                    @click="cancelCreateCard"
                    variant="link"
                >
                    {{ $t("Cancel") }}
                </b-btn>
            </div>
        </div>

        <b-form-input
            v-model="card"
            autocomplete="cc-number"
            inputmode="numeric"
            class="input-element"
            :placeholder="$t('Card Number')"
            size="lg"
        />

        <b-row no-gutters class="mt-2">
            <b-col cols="3">
                {{ $t("Expiration") }}
            </b-col>
            <b-col cols="3"> </b-col>
            <b-col cols="4" offset="2">
                {{ $t("Security Code") }}
            </b-col>
        </b-row>

        <b-row no-gutters>
            <b-col cols="3">
                <b-form-select
                    v-model="month"
                    :options="expirationMonths"
                    autocomplete="cc-exp-month"
                    size="lg"
                >
                    <template #first>
                        <b-form-select-option :value="null" disabled>
                            <span v-if="$screen.md">
                                {{ $t("Month") }}
                            </span>
                            <span v-else>
                                {{ $t("MM") }}
                            </span>
                        </b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>
            <b-col cols="3">
                <b-form-select
                    v-model="year"
                    :options="expirationYears"
                    autocomplete="cc-exp-year"
                    size="lg"
                >
                    <template #first>
                        <b-form-select-option :value="null" disabled>
                            <span v-if="$screen.md">
                                {{ $t("Year") }}
                            </span>
                            <span v-else>
                                {{ $t("YY") }}
                            </span>
                        </b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>
            <b-col cols="4" offset="2">
                <b-form-input
                    type="text"
                    v-model="code"
                    autocomplete="cc-csc"
                    size="lg"
                    :placeholder="$t('CVV')"
                ></b-form-input>
            </b-col>
        </b-row>

        <div class="mt-2">
            {{ $t("Country") }}
        </div>
        <b-select
            size="lg"
            v-model="countryId"
            :options="countriesMapped"
            autocomplete="off"
        >
        </b-select>

        <div v-if="!skipPostcode">
            <div class="mt-2">
                {{ zipOrPostalCode }}
            </div>
            <b-form-input
                type="text"
                v-model="postalCode"
                autocomplete="postal-code"
                size="lg"
                :placeholder="zipOrPostalCode"
            ></b-form-input>
        </div>

        <div class="mt-3 d-flex align-items-center justify-content-between">
            <b-form-checkbox v-model="save" switch size="lg" class="mr-2">
                {{ $t("Save card") }}
                <span v-if="$screen.md">
                    {{ $t("for future purchases") }}
                </span>
            </b-form-checkbox>
            <b-btn variant="light" pill @click="saveCardModal = true">
                <font-awesome-icon
                    class="text-success"
                    icon="fa-duotone fa-shield-check"
                />
            </b-btn>
        </div>

        <b-modal
            :title="$t('Payment Security')"
            v-model="saveCardModal"
            hide-footer
            centered
        >
            <h5 class="text-success">
                <font-awesome-icon
                    icon="fa-duotone fa-shield-check"
                    size="lg"
                />

                {{ $t("Your card details are secure") }}
            </h5>
            <div class="mt-2">
                {{
                    $t(
                        "Our payment processor will provide us with a unique ID that represents your card, enabling our system to charge your card upon your request"
                    )
                }}
            </div>
            <div class="mt-2">
                {{
                    $t(
                        "Your full card number, expiration date, and security code are never stored on our systems"
                    )
                }}
            </div>
        </b-modal>

        <b-btn
            :disabled="disabled"
            @click="createPaymentMethodAndCharge"
            size="lg"
            variant="primary"
            pill
            block
            class="mt-3"
        >
            {{ $t("Pay") }} ${{ price.amount }}
            <div class="small text-opaque">
                {{ price.localPrice }}
            </div>
        </b-btn>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            saveCardModal: false,
            save: false,
            countryId: null,
            card: null,
            month: null,
            year: null,
            code: null,
            postalCode: null,
        };
    },
    created() {
        if (this.onboardingUser.countryId) {
            this.countryId = this.onboardingUser.countryId;
        }
    },
    computed: {
        selectedCountry() {
            return this.countries.find(
                (country) => country.id === this.countryId
            );
        },
        skipPostcode() {
            return this.selectedCountry?.doesntUsePostcode;
        },
        zipOrPostalCode() {
            return this.selectedCountry?.zip ? "ZIP Code" : "Postal Code";
        },
        countriesMapped() {
            return this.countries.map((country) => ({
                value: country.id,
                text: country.name,
            }));
        },
        ...mapState("options", [
            "expirationYears",
            "expirationMonths",
            "countries",
        ]),
        ...mapState("wallet", ["price"]),
        ...mapState("brand", ["brand"]),
        ...mapState("authorize", ["saving", "error"]),
        expiration() {
            return this.year && this.month;
        },
        complete() {
            return (
                this.card &&
                this.expiration &&
                this.code &&
                (this.postalCode || this.skipPostcode)
            );
        },
        disabled() {
            return this.saving || !this.complete;
        },
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        cancelCreateCard() {
            this.$store.commit("wallet/setCreateCard", false);
        },
        changePackage() {
            this.$store.commit("wallet/setPrice", null);
        },
        createPaymentMethodAndCharge() {
            this.$store.dispatch("authorize/transaction", {
                priceId: this.price.id,
                card: this.card,
                year: this.year,
                month: this.month,
                code: this.code,
                postalCode: this.postalCode,
                countryId: this.countryId,
                save: this.save,
            });
        },
    },
};
</script>